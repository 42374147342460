import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Moment from 'react-moment';
import BasePage from '../Components/BasePage';
import Api from "../Services/Api";
import bikePreloader from '../images/bike_preloader.gif'

/***** CUSTOMER *****/
function LatestRentals() {
	const [apiResponse, setApiResponse] = useState(null)

	let history = useHistory();

	const getApiData = () => {		
		Api.get({endpoint: `customer/listAllRentals`, contentType : 'application/json'})
		.then(response => {
			var rentals = response.data;
			setApiResponse(rentals);
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				alert("Utente disabilitato");
				history.replace('/');
				return;
			}
			history.replace('/homeCustomer');
		});
	}
	
	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps
	
	return (
		<BasePage title="Elenco dei tuoi NOLEGGI">
			{apiResponse ? (
				<div className="container pt-2 pb-5">
					<div className="table-responsive">
						<table className="table shadow">
							<thead className="bckRed text-white">
								<tr>
									<th scope="col">Inizio</th>
									<th scope="col">Termine</th>
									<th scope="col">Costo noleggio</th>
									<th scope="col">Costo noleggio scontato</th>
									<th scope="col">Esito noleggio</th>
									<th scope="col">Promozione</th>
									<th scope="col">Veicolo</th>
									<th scope="col">Punto NOLEGGIO</th>
									<th scope="col">Luogo NOLEGGIO</th>
								</tr>
							</thead>
							<tbody>
								{
									apiResponse.rentals.map(function(rent, i) {
										return (
											<tr key={rent.idRental}>
												<td><Moment format="DD/MM/YYYY HH:mm:ss">{rent.start_date}</Moment></td>
												<td><Moment format="DD/MM/YYYY HH:mm:ss">{rent.stop_date}</Moment></td>
												<td>
													{rent.tot_public_discounted_price ? (
														<span style={{'text-decoration': 'line-through'}}>
															{rent.tot_public_price}
														</span>
													) : (
														<>
															{rent.tot_public_price}
														</>
													)}
												</td>
												<td>{rent.tot_public_discounted_price}</td>
												<td>{rent.exit_code}</td>
												<td>{rent.discount_code}</td>
												<td>{rent.veicle}</td>
												<td>{rent.supplier}</td>
												<td>{rent.supplierLocation}</td>											
											</tr>
										)
									})
								}
							</tbody>
						</table>								
					</div>
				</div>
			):(
				<div className="text-center">
					<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
				</div>
			)}
		</BasePage>
	);		
}

export default LatestRentals;