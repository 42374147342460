import React, {Fragment} from 'react';
import './css/BasePage.css';

function BasePage(props) {
	return (
		<Fragment>			
			{props.subtitle ? (
				<>
					<p className="pageTitleWithSub">{ props.title }</p>
					<p className="pageSubtitle">{ props.subtitle }</p>
				</>
			):(
				<p className="pageTitle">{ props.title }</p>
			)}
			{props.children}
		</Fragment>
	);
}

export default BasePage;

