import React from 'react';
import { useState } from "react"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import loadingGif from '../images/loading.gif'
import BasePage from '../Components/BasePage';
import {MAIN_SITE} from "../Config/constants";
import Api from "../Services/Api";
import { Modal } from "react-responsive-modal";
import './css/Signin.css';

/***** PIBLIC *****/
function Signin() {
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [modalOpen, setModalOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [underage, setUnderage] = useState(false);
	const [promo, setPromo] = useState(null);

	const promoText = (code) => {
		if (code.localeCompare('1H_Free') === 0) {
			return `Con la tua registrazione usufruisci<br /><em>della prima ora di noleggio completamente gratuita</em>.<br />\
				Nessuna attivazione e nessun costo<br />\
				<i class="fa fa-clock-o fa-2x" aria-hidden="true">&nbsp;GRATIS</i>`	// eslint-disable-line no-multi-str
		}
		return null;
	}

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	}
	const handleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	}

	const onCloseModal = () => {
		setModalOpen(false);
	};

	const formik = useFormik({
		initialValues: {
			name: '',
			surname: '',
			birth_date: '',
			phone_number: '',
			email: '',
			email_confirmation: '',
			password: '',
			password_confirmation: '',
			chkPrivacy: false,
		},
		validationSchema: Yup.object({
			name:
				Yup.string()
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			surname:
				Yup.string()
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			birth_date:
				Yup.date()
				.required('Campo obbligatorio'),
			phone_number:
				Yup.string()
				.matches(/^(\+)?\d{9,13}$/, 'Formato non valido')
				.required('Campo obbligatorio'),
			email:
				Yup.string()
				.email('Formato email non valido')
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			email_confirmation:
				Yup.string()
				.required('Campo obbligatorio')
				.oneOf([Yup.ref('email')], 'Le email non corrispondono'),
			password:
				Yup.string()
				.min(8, 'Lunghezza minima 8 caratteri')
				.required('Campo obbligatorio'),
			password_confirmation:
				Yup.string()
				.required('Campo obbligatorio')
				.oneOf([Yup.ref('password')], 'Le password non corrispondono'),
			chkPrivacy:
				Yup.boolean()
				.oneOf([true],'Selezione obbligatoria'),
		}),
		onSubmit: values => {
			setLoading(true);

			try {
				Api.post({endpoint: 'register', body: values, contentType : 'application/json'})
					.then(response => {
						if (response.data.underage) {
							setUnderage(true);
							setModalOpen(true);
						}
						if (response.data.promo) {
							var prTxt = promoText(response.data.promo);
							if (prTxt) {
								setPromo(prTxt);
								setModalOpen(true)
							}
						}
						setDisplayAlert(1);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						if (error.response.status === 401) {
							alert('Impossibile procedere alla registrazione di utenti minori di ' + error.response.data.error + " anni");
							setDisplayAlert(0);
							setLoading(false);
							return;
						}

						console.log(error);
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
	});
	return (
		<BasePage title="Registrati">
			<div className="container pt-5 pb-5">
				<div style={{'textAlign':'left'}}>
					<form onSubmit={formik.handleSubmit}>
						<div className="row">
							<div className="col-md-6 form-group">
								<fieldset>
									<label htmlFor="name" className="form-label required">Nome</label>
									<input
										disabled={loading}
										id="name"
										name="name"
										type="text"
										className="form-control"
										placeholder="Nome"
										aria-label="Nome"
										{...formik.getFieldProps('name')}
									/>
									{formik.touched.name && formik.errors.name ? (
										<div className="validationMessage">{formik.errors.name}</div>
									) : null}
								</fieldset>
							</div>
							<div className="col-md-6 form-group">
								<fieldset>
									<label htmlFor="surname" className="form-label required">Cognome</label>
									<input
										disabled={loading}
										id="surname"
										name="surname"
										type="text"
										className="form-control"
										placeholder="Cognome"
										aria-label="Cognome"
										{...formik.getFieldProps('surname')}
									/>
									{formik.touched.surname && formik.errors.surname ? (
										<div className="validationMessage">{formik.errors.surname}</div>
									) : null}
								</fieldset>
							</div>
						</div>
						<div className="row pt-3">
							<div className="col-md-6 form-group">
								<fieldset>
									<label htmlFor="birth_date" className="form-label required">Data di nascita</label>
									<input
										disabled={loading}
										id="birth_date"
										name="birth_date"
										type="date"
										className="form-control"
										aria-label="Data di nascita"
										{...formik.getFieldProps('birth_date')}
									/>
									{formik.touched.birth_date && formik.errors.birth_date ? (
										<div className="validationMessage">{formik.errors.birth_date}</div>
									) : null}
								</fieldset>
							</div>
							<div className="col-md-6 form-group">
								<fieldset>
									<label htmlFor="phone_number" className="form-label required">Numero telefonico</label>
									<input
										disabled={loading}
										id="phone_number"
										name="phone_number"
										type="text"
										className="form-control"
										placeholder="No spazi - eventuale prefisso +39 o altri prefissi internazionali"
										aria-label="Numero telefonico"
										{...formik.getFieldProps('phone_number')}
									/>
									{formik.touched.phone_number && formik.errors.phone_number ? (
										<div className="validationMessage">{formik.errors.phone_number}</div>
									) : null}
								</fieldset>
							</div>
						</div>
						<div className="row pt-3">
							<div className="col-md-6 form-group">
								<fieldset>
									<label htmlFor="email" className="form-label required">Email</label>
									<input
										disabled={loading}
										id="email"
										name="email"
										type="email"
										className="form-control"
										placeholder="Email"
										aria-label="Email"
										{...formik.getFieldProps('email')}
									/>
									{formik.touched.email && formik.errors.email ? (
										<div className="validationMessage">{formik.errors.email}</div>
									) : null}
								</fieldset>
							</div>
							<div className="col-md-6 form-group">
								<fieldset>
									<label htmlFor="email_confirmation" className="form-label required">Conferma Email</label>
									<input
										disabled={loading}
										id="email_confirmation"
										name="email_confirmation"
										type="email"
										className="form-control"
										placeholder="Conferma Email"
										aria-label="Conferma Email"
										{...formik.getFieldProps('email_confirmation')}
									/>
									{formik.touched.email_confirmation && formik.errors.email_confirmation ? (
										<div className="validationMessage">{formik.errors.email_confirmation}</div>
									) : null}
								</fieldset>
							</div>
						</div>
						<div className="row pt-3">
							<div className="col-md-6 form-group">
								<fieldset>
									<label htmlFor="password" className="form-label required">Password (min. 8 caratteri)</label>
									<input
										disabled={loading}
										id="password"
										name="password"
										type={showPassword?"text":"password"}
										className="form-control"
										placeholder="Nuova Password"
										aria-label="Nuova Password"
										autoComplete="off"
										{...formik.getFieldProps('password')}
									/>
									{showPassword ? (
										<i className="fa fa-eye-slash faInsideInput" aria-hidden="true" onClick={handleShowPassword}></i>
									):(
										<i className="fa fa-eye faInsideInput" aria-hidden="true" onClick={handleShowPassword}></i>
									)}
									{formik.touched.password && formik.errors.password ? (
										<div className="validationMessage">{formik.errors.password}</div>
									) : null}
								</fieldset>
							</div>
							<div className="col-md-6 form-group">
								<fieldset>
									<label htmlFor="password_confirmation" className="form-label required">Conferma Password</label>
									<input
										disabled={loading}
										id="password_confirmation"
										name="password_confirmation"
										type={showConfirmPassword?"text":"password"}
										className="form-control"
										placeholder="Conferma Password"
										aria-label="Conferma Password"
										autoComplete="off"
										{...formik.getFieldProps('password_confirmation')}
									/>
									{showConfirmPassword ? (
										<i className="fa fa-eye-slash faInsideInput" aria-hidden="true" onClick={handleShowConfirmPassword}></i>
									):(
										<i className="fa fa-eye faInsideInput" aria-hidden="true" onClick={handleShowConfirmPassword}></i>
									)}
									{formik.touched.password_confirmation && formik.errors.password_confirmation ? (
										<div className="validationMessage">{formik.errors.password_confirmation}</div>
									) : null}
								</fieldset>
							</div>
						</div>
						<div className="row pt-3">
							<div className="col-md-12 form-group">
								<fieldset>
									<input
										disabled={loading}
										id="chkPrivacy"
										name="chkPrivacy"
										className="form-check-input"
										type="checkbox"
										{...formik.getFieldProps('chkPrivacy')}
									/>
									<label className="form-check-label mx-2" htmlFor="chkPrivacy">Autorizzo il trattamento dei miei dati personali secondo quanto indicato nell'<a className="dark underline" targe="_blank" href={MAIN_SITE + "/downloads/eBikeSharingProject/informativa-privacy.pdf"}>Informativa privacy</a></label>
									{formik.errors.chkPrivacy ? (
										<div className="validationMessage">{formik.errors.chkPrivacy}</div>
									) : null}
								</fieldset>
							</div>
						</div>
						<div className="row pt-3">
							<div className="col">
								{loading && (
										<>
											<>
											{displayAlert === 0 && (
												<img className="pl-3" src={loadingGif} alt="Loading..."/>
											)}
											</>
											<>
											{displayAlert === -1 && (
												<div id="alertFail" className="alert alert-danger" role="alert">Si è verificato un errore imprevisto. Riprovare più tardi</div>
											)}
											</>
											<>
											{displayAlert === 1 && (
												<div id="alertSuccess" className="alert alert-success" role="alert">
													Registrazione completata con successo!<br />
													Clicca sul link che ti abbiamo inviato via email per attivare l'account<br/>
													<strong>ATTENZIONE: </strong>Controlla anche nella cartella SPAM
												</div>
											)}
											</>
										</>
								)}
								{!loading && (
									<button type="submit" className="btn btn-primary buttonRed">Registrati</button>
								)}
							</div>
						</div>
					</form>
				</div>
			</div>
			<Modal open={modalOpen} onClose={onCloseModal}>
				{underage && (
					<>
						<h2>ATTENZIONE</h2>
						<p>Gli utenti minori di 18 anni possono utilizzare i servizi della piattaforma ESCLUSIVAMENTE sotto la costante supervisione di un adulto.</p>
						<p>In fase di noleggio, sarà cura dell'utente presentare un utente maggiorenne che agisca da tutor durante la fase di uso del servizio.</p>
					</>
				)}
				{promo && (
					<>
						<h1>PROMO</h1>
						<p dangerouslySetInnerHTML={{__html: promo}}></p>
					</>
				)}
			</Modal>
		</BasePage>
	);
}

export default Signin;