import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth/ProvideAuth'
import { useHistory } from "react-router-dom";
import Card from '../Components/Card';

import { DEV, MAIN_SITE, APP_NAME } from "../Config/constants";

function Index() {
	let auth = useAuth();
	let history = useHistory();

	if (auth.solUser) {
		var homePath;
		switch (auth.solUser.roles[0].name) {
			case 'supplier':
				homePath = '/homeSupplier';
				break;
			case 'customer':
				homePath = '/homeCustomer';
				break;
			default:
				homePath = "/";
		}
		history.replace(homePath);
	}

	return (
		<div className="container pt-5 pb-5">
			<div className="row">
				<div className="col">
					<a href={MAIN_SITE} target="_blank" rel="noreferrer">
						<Card
							titolo="IL PROGETTO"
							testo="Vai sul sito e scopri l'eBike Sharing Project&reg;"
							icona="fa fa-external-link fa-4x"
						/>
					</a>
				</div>
			</div>

			{/* DEV ONLY */}
			{!DEV && (
				<div className="row">
					<div className="col">
						<Link to="/notices">
							<Card
								titolo="EVENTI & PROMOZIONI"
								testo="Eventi e promozioni dei nostri partner"
								gIcona="mark_email_unread"
							/>
						</Link>
					</div>
				</div>
			)}

			<div className="row">
				<div className="col">
					<Link to="/ourVeicles">
						<Card
							titolo="I NOSTRI VEICOLI"
							testo="Visualizza i nostri veicoli	 eMobility"
							gIcona="electric_car"
						/>
					</Link>
				</div>
			</div>
			
			<div className="row">
				<div className="col">
					<Link to="/servicePoints">
						<Card
							titolo="Punti NOLEGGIO e RICARICA"
							testo="Scopri i Punti dove noleggiare e ricaricare i nostri veicoli eMobility"
							icona="fa fa-map-o fa-4x"
						/>
					</Link>
				</div>
			</div>
			
			<div className="row">
				<div className="col">
					<Link to="/login">
						<Card
							titolo="ACCEDI"
							testo={`Accedi a ${APP_NAME}`}
							icona="fa fa-sign-in fa-4x"
						/>
					</Link>
				</div>
			</div>
			
			<div className="row">
				<div className="col">
					<Link to="/signin">
						<Card
							titolo="REGISTRATI"
							testo="Registrati per usufruire dei nostri Servizi & Prodotti"
							icona="fa fa-user-o fa-4x"
						/>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Index;