import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BasePage from '../Components/BasePage';
import qrCodeImg from '../images/qrCode.jpg' 
import QrReader from 'react-qr-reader';
import ReactPinField from "react-pin-field"
import Api from "../Services/Api";
import './css/Rental.css';
import loadingGif from '../images/loading.gif' 
//import {DEV, MAPS_MAX_ACCURACY} from "../Config/constants";
import bikePreloader from '../images/bike_preloader.gif'
import { useAuth } from '../Auth/ProvideAuth'
import { Modal } from "react-responsive-modal";
import DisplayFiles from '../Components/DisplayFiles'

/***** CUSTOMER *****/
function Rental(props) {
	let history = useHistory();
	
	const [scanEnabled, setScanEnabled] = useState(0)
	const [bikeNumber, setBikeNumber] = useState(null)
	const [loading, setLoading] = useState(true);	
	const [authorized, setAuthorized] = useState(false)
	const [position, setPosition] = useState(null)
	const [modalOpen, setModalOpen] = useState(false)
	const [preAcceptFiles, setPreAcceptFiles] = useState(null)
	
	const auth = useAuth();
	
	var veicle = "";	
	if (props.veicle.localeCompare("eBike") === 0) {
		veicle = "eBike";
	}
	if (props.veicle.localeCompare("eScooter") === 0) {
		veicle = "eScooter";
	}
	if (props.veicle.localeCompare("eMoped") === 0) {
		veicle = "eMoped";
	}	
	if (props.veicle.localeCompare("eVehicle") === 0) {
		veicle = "eVehicle";
	}
	
	var rent_token = localStorage.getItem('rent_token');

	useEffect(() => {
		if (rent_token) {
			Api.get({endpoint: 'customer/currentRent/'+rent_token, contentType : 'application/json'})
				.then(response => {		
					var status = response.data.status;
					if (status.localeCompare('NORENT') !== 0) {			
						history.replace('/currentRent');
						return;
					}
					else {
						localStorage.removeItem('rent_token');
						setAuthorized(true);
						setLoading(false);
					}
				})
				.catch(error => {
					setAuthorized(true);
					if (error.response && error.response.status === 401) {
						alert("Utente disabilitato");
						history.replace('/');
						return;
					}
					if (error.response && error.response.status === 403) {
						localStorage.removeItem('rent_token');
						alert("Token non valido");
						history.replace('/homeCustomer');
						return;
					}							
					if (error.response && error.response.status === 404 && error.response.data.error.localeCompare('Unauthenticated.') === 0) {
						alert("Utente non autenticato");
						auth.signout();
						history.replace('/login');
						return;
					}						
					console.log(JSON.stringify(error));
					history.replace('/homeCustomer');
					return;
				});
		}
		else {
			setAuthorized(true);
			setLoading(false);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	
	const enableScan = () => {
		if (loading) {
			return;
		}		
		setScanEnabled(1);
	}
	
	const handleScan = data => {
		const regex = /^\d{5}$/;
		if (data) {
			if (data.toString().match(regex)) {
				setBikeNumber(data.toString());
				setScanEnabled(2);
			}
			else {
				alert("Il qrcode non è stato riconosciuto");
			}
		}
	}
	
	const onCloseModal = () => {
		alert('Noleggio annullato');
		setModalOpen(false);
	};
	
	const getPreAcceptFiles = () => {	
		try {			
			Api.get({endpoint: 'customer/preAcceptFiles/'+ bikeNumber, contentType : 'application/json'})
			.then(response => {		
				setPreAcceptFiles(response.data.files);
			})
			.catch(error => {
				if (error.response && error.response.status === 401) {
					alert("Utente disabilitato");
					history.replace('/');
					return;
				}				
				if (error.response && error.response.status === 403) {
					alert("Numero " + veicle + " non valido");
					setModalOpen(false);
					return;
				}							
				alert('Impossibile eseguire l\'operazione\nRivolgiti al fornitore per supporto');
			});
		} catch (error) {
			alert("Si è verificato un errore imprevisto. Riprovare più tardi");
		}
	}

	const confirmRent = (e) => {
		if (loading) {
			return;
		}
		const regex = /^\d{5}$/;
		if (bikeNumber) {
			if (bikeNumber.match(regex)) {
				/*
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(
						function(position) {
							if (!DEV && position.coords.accuracy > MAPS_MAX_ACCURACY) {
								alert('Il tuo posizionamento non è preciso. Verifica che il GPS sia attivo e spostati un po\' o attendere qualche secondo e riprovare');
								return;
							}
				*/
							setPosition(position);
							setModalOpen(true);
							getPreAcceptFiles();
				/*
						},
						function(error){
							if (DEV) {
								alert(JSON.stringify(error) + "\n" + error);		
								setPosition({'coords': {
										'latitude': 42.457748,
										'longitude': 13.926768,
										'accuracy': 1
								}});
								setModalOpen(true);
								getPreAcceptFiles();
							}
							else {
								if (error.constructor && error.constructor.name.localeCompare("GeolocationPositionError") === 0) {
									alert('Problema con il sistema di geolocalizzazione\nAttivare il GPS e la connessione dati');
									return;
								}
								alert("Si è verificato un errore imprevisto. Riprovare più tardi");
							}
						}, 
						{
							enableHighAccuracy: true,
							timeout : 5000
						}
					);
				} 
				else { 
					alert("Funzione non supportata dal tuo dispositivo");
				}
				*/
			}
			else
				alert("Si è verificato un errore. Riprovare a selezionare il veicolo");	
		}
		else
			alert("Selezionare il veicolo");				
	}
	const handleError = err => {
		alert("Impossibile procedere con la scansione del qrcode");
	}

	const askRent = () => {	
		try {			
			setAuthorized(false);
			var latitude = "0"; //position.coords.latitude.toString().substr(0,9).padEnd(9, '0');
			var longitude = "0"; //position.coords.longitude.toString().substr(0,9).padEnd(9, '0');
			var accuracy = "0"; //position.coords.accuracy.toFixed(2);
			Api.post({endpoint: 'customer/askRent', body: {'bikeNumber': bikeNumber, 'latitude': latitude, 'longitude': longitude, 'accuracy': accuracy}, contentType : 'application/json'})
				.then(response => {							
					setLoading(false);					
					if (response.data && response.data.rent_token) {
						localStorage.setItem('rent_token', response.data.rent_token);
						history.replace('/currentRent');
					}
					else {
						alert("Si è verificato un errore imprevisto. Riprovare più tardi");						
						setAuthorized(true);
					}					
				})
				.catch(error => {
					setLoading(false);
					setAuthorized(true);
					if (error.response && error.response.status === 400) {
						alert("Il veicolo richiesto non appartiene al fornitore vicino alla tua posizione");
						setModalOpen(false);
						return;
					}
					if (error.response && error.response.status === 401) {
						alert("Utente non autenticato");
						history.replace('/');
						return;					
					}					
					if (error.response && error.response.status === 403) {
						alert("Il tuo account ha già un noleggio all'attivo su un altro dispositivo. Impossibile continuare");						
						history.replace('/homeCustomer');
						return;
					}
					if (error.response && error.response.status === 409) {
						alert("Il veicolo richiesto è noleggiato o prenotato.\nCambia veicolo e riprova");
						setModalOpen(false);
						return;
					}
					alert("Si è verificato un errore imprevisto. Riprovare più tardi");							
				});
		} catch (error) {
			alert("Si è verificato un errore imprevisto. Riprovare più tardi");
		}
	}
	return (
		<BasePage title="Noleggia">			
				{authorized ? (
					<div className="container text-center">
						<h3 className="mb-4">Per avviare il noleggio avvicinarsi all' {veicle} e scansionare il relativo QRCode</h3>
						<div className="card mb-3 shadow border-0">
							<div className="card-body">
									<h5 className="card-title mt-2">Click sul QRCode qui sotto per attivare lo scanner</h5>
									<>
									{(scanEnabled !== 1) && (
										<img className="pl-3 pt-2 cliccable" src={qrCodeImg} alt="Scan" onClick={enableScan}/>
									)}
									</>
									<>
									{scanEnabled === 1 && (								
										<QrReader
											delay={500}
											onError={handleError}
											onScan={handleScan}
											style={{ width: '50%', margin: '0 auto' }}
										/>
									)}
									</>
									<>
									{(scanEnabled === 2 && bikeNumber) && (
										<div className="card mt-4">
											<div className="card-body">
												<h5 className="card-title textRed">Hai selezionato il veicolo n.{bikeNumber}</h5>										
												{loading ? (
													<img className="pl-3" src={loadingGif} alt="Loading..."/>
												):(
													<>
														<button type="button" className="btn btn-primary buttonRed mb-2" onClick={(e) => confirmRent(e)}>CONFERMA IL NOLEGGIO</button><br />
														<span className="text-muted">Se vuoi cambiare veicolo, clicca nuovamente sull'immagine del QRCode</span>
													</>
												)}
											</div>
										</div>
									)}
									</>
							</div>
							<h3 className="pt-2">In alternativa <br/> inserisci qui il numero dell'{veicle}</h3>
							<div className="card-body text-center">
								
								<div className="row">
									<div className="col-md-4"></div>
									<div className="col-md-4">
										<ReactPinField 
											onChange={setBikeNumber}
											length="5"
											className="pin-field"
											validate={/\d/}
											disabled={loading}
										/>
									</div>								
									<div className="col-md-4"></div>
								</div>
								{loading ? (
									<img className="pl-3" src={loadingGif} alt="Loading..."/>
								):(
									<button type="button" className="btn btn-primary buttonRed my-2 mt-4 p-3" onClick={(e) => confirmRent(e)}>CONFERMA IL NOLEGGIO</button>
								)}
							</div>
						</div>				
					</div>
				):(
					<div className="text-center">
						<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
					</div>	
				)}	
			<Modal open={modalOpen} onClose={onCloseModal}>				
				<div className="mt-4">
					<h5>Prima di iniziare leggi attentamente i Termini e le Condizioni d'Uso del Concedente e le Istruzioni d'Uso dell'{veicle}</h5>
				</div>
				{preAcceptFiles && (
					<DisplayFiles files={preAcceptFiles}/>
				)}
				<h5><u>Se prosegui con il Noleggio dichiari di aver letto ed approvato i Termini e <br/> le Condizioni d'Uso</u></h5>
				{preAcceptFiles ? (
					<button type="button" className="btn btn-primary buttonRed my-2 p-3" onClick={(e) => askRent()}>Ho letto ed approvato!</button>
				) : (
					<img className="pl-3" src={loadingGif} alt="Loading..."/>
				)}
				<p className="text-muted">Se non approvi e vuoi annullare la richiesta, chiudi questa finestra con la X in alto a destra</p>
			</Modal>
		</BasePage>
	);
}

export default Rental;