import React from 'react';

function ComingSoon() {
	return (
		<div className="container text-center">
			<div className="card mb-3 shadow border-0">
				<div className="card-body">
					<h1 className="card-title">
						<span className="textRed">Questo servizio sarà presto disponibile!!!</span>
					</h1>
					<p><em>Stiamo lavorando costantemente al miglioramento dell'app ed all'aggiunta di tanti nuovi servizi</em></p>
				</div>
			</div>
		</div>
	);
}

export default ComingSoon;