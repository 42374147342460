import { useState, useRef, Fragment } from "react";
import { useHistory } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';

import { useAuth, useForceUpdate } from '../Auth/ProvideAuth'
import loadingGif from '../images/loading.gif' 
import {MAIN_SITE} from "../Config/constants";
import Api from "../Services/Api";

/***** PUBLIC *****/
function Login() {		
	let history = useHistory();
	let auth = useAuth();
		
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(false);
	const [errorLogin, setErrorLogin] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	}
	
	const simpleValidator = useRef(
		new SimpleReactValidator({
			messages: {
				required: 'Campo :attribute obbligatorio',
				email: 'Inserire un indirizzo email corretto',
				min: 'Lunghezza minima :min caratteri',
				max: 'Lunghezza massima :max caratteri',
			},
		})
	);
	
	const [forceUpdate] = useForceUpdate();

	function sendVerificationEmail() {			
		if (!simpleValidator.current.allValid()) {
			simpleValidator.current.showMessages();
			forceUpdate();
			return;
		}
  
		setLoading(true);

		try {	
			Api.get({endpoint: 'sendVerificationEmail/' + email, contentType : 'application/json'})
				.then(response => {
					setDisplayAlert(1);
					setLoading(false);
				})
				.catch(error => {
					setErrorLogin("Si è verificato un errore imprevisto. Riprovare più tardi");
					setDisplayAlert(-1);
					setLoading(false);
				});
		} catch (error) {
			console.log(error);
			setDisplayAlert(-1);
		}
	};
	
	
	const handleSubmit = async (e) => {			
		e.preventDefault();
		
		if (!simpleValidator.current.allValid()) {
			simpleValidator.current.showMessages();
			forceUpdate();
			return;
		}
  
		setLoading(true);

		try {
            let response = await auth.signin({email, password});
			setLoading(false);
			if (response.status === 401) {
				setErrorLogin("Nome utente o password non validi");
				setDisplayAlert(-1);
			    return;				
			}
			if (response.status === 422) {
				if (response.error.localeCompare('Email not verified') === 0) {
					setErrorLogin("Indirizzo email non verificato - Utilizza il pulsante in basso se non hai ricevuto il link");
					setDisplayAlert(-2);
				}
				if (response.error.localeCompare('User disabled') === 0) {
					setErrorLogin("Account disattivato - Contattare l'amministratore");
					setDisplayAlert(-1);

				}
			    return;				
			}
			if (response.status !== 200) {
				setErrorLogin("Si è verificato un errore imprevisto. Riprovare più tardi");
				setDisplayAlert(-1);
			    return;
            }

			switch (response.role) {
				case 'administrator':
					console.log('Disabled role');
					break;
				case 'supplier':
					history.replace('/homeSupplier');
					break;
				case 'customer':
					history.replace('/homeCustomer');
					break;
				default:
					console.log('Are you crazy?');
			}					
		} catch (error) {
			console.log(error);
			setErrorLogin("Si è verificato un errore imprevisto. Riprovare più tardi");
			setLoading(false);
			return;
		}
	};	
	
	return (
		<Fragment>
				<div className="container pt-5">
					<form onSubmit={handleSubmit}>
						<div className="mb-3">
							<div className="form-group">
								<fieldset>
									<label htmlFor="email">Nome utente o Email</label>
									<input 
										type="email" 
										name="email" 
										className="form-control" 
										placeholder="Email" 
										id="email" 
										maxLength="50" 
										disabled={loading}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</fieldset>
								{simpleValidator.current.message('email', email, 'required|email')}
							</div>
						</div>
						<div className="mb-3">
							<div className="form-group">
								<fieldset>
									<label htmlFor="password">Password:</label>
									<input 
										type={showPassword?"text":"password"}
										name="password" 
										className="form-control" 
										placeholder="Password" 
										id="password" 
										autoComplete="off"
										disabled={loading}
										onChange={(e) => setPassword(e.target.value)}
									/>
									{showPassword ? (
										<i className="fa fa-eye-slash faInsideInput" aria-hidden="true" onClick={handleShowPassword}></i>
									):(
										<i className="fa fa-eye faInsideInput" aria-hidden="true" onClick={handleShowPassword}></i>
									)}									
								</fieldset>
								{simpleValidator.current.message('password', password, 'required|min:8|max:50')}							
							</div>
						</div>
					</form>
					{loading?
						<img className="pl-3" src={loadingGif} alt="Loading..."/>
					:
						<button type="button" className="btn btn-primary buttonRed" onClick={(e) => handleSubmit(e)}>ACCEDI</button>
					}
					{displayAlert === 1 && (
						<div id="alertSuccess" className="alert alert-success mt-2" role="alert">Clicca sul link che ti abbiamo inviato via email per attivare l'account</div>
					)}					
					{(displayAlert === -1 || displayAlert === -2) && (
						<div id="alertFail" className="alert alert-danger" role="alert" style={{'marginTop': '15px'}}>{errorLogin}</div>
					)}
				</div>
			<div className="container">
				<div className="row">
					<div className="col">					
						{displayAlert === -2 && (
							<button type="button" className="btn btn-primary buttonRed" onClick={sendVerificationEmail} disabled={loading}>
								Invia il link di verifica 
							</button>						
						)}
						
					</div>
					<div className="col mb-2" style={{'textAlign': 'right'}}>
						<a href={`${MAIN_SITE}/password/reset`} target="_blank" rel="noreferrer">
							<button type="button" className="btn btn-light mb-2" disabled={loading}>
								Per recuperare le tue credenziali clicca qui
							</button>						
						</a>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default Login;
