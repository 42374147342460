import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import BasePage from '../Components/BasePage';
import IconButton from '../Components/IconButton';
import Api from "../Services/Api";
import bikePreloader from '../images/bike_preloader.gif'
import { Link } from 'react-router-dom';

function Notices() {
	const [apiResponse, setApiResponse] = useState(null)

	let history = useHistory();

	const getApiData = () => {
		Api.get({endpoint: `service/publicListNotices`, contentType : 'application/json'})
		.then(response => {
			var notices = response.data;
			setApiResponse(notices);
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				alert("Utente disabilitato");
				history.replace('/');
				return;
			}
			history.replace('/');			
		});
	}

	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps


	return (
		<BasePage title="Eventi & promozioni">
			{apiResponse ? (
				<ul className="list-inline" style={{textAlign: 'center'}}>
					{
						apiResponse.notices.map(function(notice, i) {
							return (
								<li key={i} className="list-inline-item">
									<Link to={`/noticesDetails/${notice.category}`}>
										<IconButton
											titolo={notice.category}
											badge={notice.num}
											gIcona="keyboard_double_arrow_down"
										/>
									</Link>
								</li>
							)
						})
					}
				</ul>
			):(
				<div className="text-center">
					<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
				</div>
			)}
		</BasePage>
	);
}

export default Notices;