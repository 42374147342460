import React, { forwardRef, useImperativeHandle, useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Circle, MarkerClusterer, Marker } from '@react-google-maps/api';
import { MAPS_KEY, MAPS_CIRCLE_RADIUS, MAPS_INIT_ZOOM, MAPS_CIRCLE_MARKER, MAPS_DUMMY_MARKER, GOOGLE_MAP_LINK } from "../Config/constants";
import './css/GMap.css';

const containerStyle = {
	width: '99%',
	height: '70vh',
	marginBottom: '10px'
};

const center = {
	lat: 42.5,
	lng: 12.0
};

const circleOptions = {
	strokeColor: '#2a8bfa',
	strokeOpacity: 0.8,
	strokeWeight: 2,
	fillOpacity: 0,
	clickable: false,
	draggable: false,
	editable: false,
	visible: true,
	radius: parseInt(MAPS_CIRCLE_RADIUS),
	zIndex: 1
}

const GMap = forwardRef((props, ref) => {
	var markers = {}

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: MAPS_KEY
	})

	const [circleCenter, setCircleCenter] = useState(null)
	const [localInfoWindow, setLocalInfoWindow] = useState(null)
	const [dummyMarkerInfoWindow, setDummyMarkerInfoWindow] = useState(null)
	const [myMap, setMyMap] = useState(null)

	const clusterOptions = {
		imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
		maxZoom: 12,
	}

	useImperativeHandle(ref, () => ({
		centerMap(latitude, longitude) {
			var centerLocation = new google.maps.LatLng(latitude, longitude);
			myMap.setZoom(18);
			myMap.setCenter(centerLocation);
		}
	}));

	const onLoad = useCallback(function callback(map) {

		setMyMap(map);

		function CenterControl(controlDiv: Element, map: google.maps.Map) {
		  const controlUI = document.createElement("div");
		  controlUI.style.backgroundColor = "#fff";
		  controlUI.style.border = "2px solid #fff";
		  controlUI.style.borderRadius = "2px";
		  controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
		  controlUI.style.cursor = "pointer";
		  controlUI.style.marginTop = "8px";
		  controlUI.style.marginBottom = "22px";
		  controlUI.style.marginRight = "10px";
		  controlUI.style.width = "40px";
		  controlUI.style.height = "40px";
		  controlUI.style.textAlign = "center";
		  controlUI.title = "Trova la tua posizione";
		  controlDiv.appendChild(controlUI);
		  const controlText = document.createElement("div");
		  controlText.style.color = "var(--bs-primary)";
		  controlText.style.fontFamily = "Roboto,Arial,sans-serif";
		  controlText.style.fontSize = "16px";
		  controlText.style.lineHeight = "38px";
		  controlText.style.paddingLeft = "5px";
		  controlText.style.paddingRight = "5px";
		  controlText.innerHTML = "<i class=\"fa fa-crosshairs\" aria-hidden=\"true\"></i>";
		  controlUI.appendChild(controlText);
		  controlUI.addEventListener("click", () => {

			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					function(position) {
						var yourLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
						map.setCenter(yourLocation);
						map.setZoom(11);
						setCircleCenter(yourLocation);
						var radiusKm = parseInt(MAPS_CIRCLE_RADIUS);
						radiusKm = radiusKm / 1000;
						var mex = "Ti stiamo mostrando un raggio di " + radiusKm + " km attorno a te!";
						if (props.type.localeCompare("publicListSuppliers") === 0)
							mex += "\nCerca i nostri punti di noleggio più vicini";
						alert(mex);
					},
					function(error){
						alert(error.message);
					},
					{
						enableHighAccuracy: true,
						timeout : 5000
					}
				);
			}
			else {
				alert("Funzione non supportata dal tuo dispositivo");
			}
		  });
		}

		const centerControlDiv = document.createElement("div");
		CenterControl(centerControlDiv, map);
		map.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv);
	}, [])	// eslint-disable-line react-hooks/exhaustive-deps

	const onUnmount = useCallback(function callback(map) {
		setMyMap(null);
	}, [])		// eslint-disable-line react-hooks/exhaustive-deps

	const onClickMarker = marker => {
		var lat = marker.latLng.lat().toString();
		if (lat.length === 8)
			lat = lat + "0";
		var lng = marker.latLng.lng().toString();
		if (lng.length === 8)
			lng = lng + "0";
		var keyClustered = lat + "C" + lng
		var key = lat + "L" + lng
		var currentMarker

		const infowindowPosition = {
			lat: marker.latLng.lat(),
			lng: marker.latLng.lng()
		};

		if (markers[key] !== undefined)
			currentMarker = markers[key];
		else
			currentMarker = markers[keyClustered];

		if (localInfoWindow !== null) {
			localInfoWindow.close();
			setLocalInfoWindow(null);
			dummyMarkerInfoWindow.setMap(null);
			setDummyMarkerInfoWindow(null);
		}

		var infoDummyMarker = new google.maps.Marker({
			position: infowindowPosition,
			map: myMap,
			icon: {url: process.env.PUBLIC_URL+'/images/markers/'+MAPS_DUMMY_MARKER+'.png'},
		});
		setDummyMarkerInfoWindow(infoDummyMarker);

		var content = "";
		var mapHref = GOOGLE_MAP_LINK.replace('%lat', currentMarker.latitude).replace('%lng', currentMarker.longitude);
		content = "<div class=\"infoWindow\">";
		if (props.type.localeCompare("publicListSuppliers") === 0) {
			content += "<span class=\"mb-2\">" + currentMarker.category + "</span><hr style=\"margin:1px 0px\"/>";
			if (!currentMarker.paused) {
				content += "<a href=\"web/" + currentMarker.safe_url + "\"><p class=\"infoTitle mt-2\">"+currentMarker.brand+"</p>";
				content += "<span class=\"infoAddress\">"+currentMarker.address+"</span></a><br />";

				content += "<div class=\"row infoButton\">";
				content += "<div class=\"col-sm-4 px-1\">";
				content += "<a href=\"web/" + currentMarker.safe_url + "\"><button class=\"infoButton\">Sito Web</button></a>";
				content += "</div>";				
				content += "<div class=\"col-sm-4 px-1\">";
				content += "<a target=\"_blank\" href=\"" + mapHref + "\"><button class=\"infoButton\">Indicazioni</button></a>";
				content += "</div>";
				content += "<div class=\"col-sm-4 px-1\">";
				content += "<a href=\"tel:"+currentMarker.phone+"\"><button class=\"infoButton\">Telefono</button></a>";
				content += "</div>";
				content += "</div>";
				
				content += "<hr style=\"margin:1px\"/>";
				if (currentMarker.nBikes && currentMarker.nBikes > 0) {
					content += "<span><i class=\"fa fa-bicycle\" aria-hidden=\"true\"></i> "+currentMarker.nBikes+" eBike</span><br/>";
					content += "<span><i class=\"fa fa-battery-three-quarters\" aria-hidden=\"true\"></i> "+currentMarker.nBikes+" Batterie</span><br/>";
					content += "<span><i class=\"fa fa-bicycle\" aria-hidden=\"true\"></i> <strong>+</strong> eBike prenotabili</span><br />";
				}
				else {
					content += "<span><i class=\"fa fa-bicycle\" aria-hidden=\"true\"></i> <strong>+</strong> eBike prenotabili</span><br />";
				}
				content += "<span><i class=\"fa fa-plug\" aria-hidden=\"true\"></i> Ricarica</span><br />";
				content += "<span><i class=\"fa fa-product-hunt\" aria-hidden=\"true\"></i>&nbsp;Parcheggio</span>";
			}
			else {
				content += "<p class=\"infoTitle\">"+currentMarker.brand+"</p>";
				content += "<hr style=\"margin:1px\"/>";
				content += "Punto NOLEGGIO e RICARICA NON ATTIVO";
			}
		}
		if (props.type.localeCompare("listSupplierBikes") === 0) {
			content += "<p class=\"infoTitle text-center\">" + currentMarker.bikeNumber + " " + currentMarker.bikeModel + "</p>";
			content += "<a target=\"_blank\" href=\"" + mapHref + "\"><p class=\"text-center\">Vedi su Google Maps</p></a><hr style=\"margin:1px\"/>";
			content += "<ul><li>Numero: " + currentMarker.bikeNumber + "</li><li>Telaio: " + currentMarker.frameNumber + "</li>";
			content += "<li>Ultimo posizionamento: " + currentMarker.gpsTime + "</li><li>Ultimo contatto: " + currentMarker.hbTime + "</li></ul>";
		}
		content += "</div>";

		var infowindow = new google.maps.InfoWindow();
		infowindow.setContent(content);
		infowindow.open(myMap, infoDummyMarker);
		setLocalInfoWindow(infowindow);

		if (props.callback) {
			props.callback(currentMarker);
		}
	}

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={parseFloat(MAPS_INIT_ZOOM)}
			onLoad={onLoad}
			onUnmount={onUnmount}
		>
			{circleCenter && (
				<>
					<Circle
						center={circleCenter}
						options={circleOptions}
					/>
					<Marker
						position={circleCenter}
						icon = {process.env.PUBLIC_URL+'/images/markers/'+MAPS_CIRCLE_MARKER+'.png'}
						title="Tu sei qui!"
					/>)
				</>
			)}

			{props.clusteredLocations && (
				<MarkerClusterer options={clusterOptions}>
					{(clusterer) =>
						props.clusteredLocations.map((location) => (
							<Marker
								key={location.latitude + "C" + location.longitude}
								position={
									{
										lat: parseFloat(location.latitude),
										lng: parseFloat(location.longitude)
									}
								}
								icon = {process.env.PUBLIC_URL+'/images/markers/'+location.icon+'.png'}
								clusterer={clusterer}
								title={location.title}
								onClick={onClickMarker}
							/>
						))
					}
				</MarkerClusterer>
			)}
			{props.locations && (
				props.locations.map((location) => {
					markers[location.latitude + "L" + location.longitude] = location;

					var myIcon = null;
					if (props.type.localeCompare("publicListSuppliers") === 0)
						myIcon = process.env.PUBLIC_URL+'/images/markers/'+location.icon+'.png';
					if (props.type.localeCompare("listSupplierBikes") === 0) {
						if (location.bikeStatus.localeCompare("NORENT") === 0)
							myIcon = process.env.PUBLIC_URL+'/images/markers/'+location.iconOn+'.png';
						else
							myIcon = process.env.PUBLIC_URL+'/images/markers/'+location.iconOff+'.png';
					}
					return (
					<Marker
						key={location.latitude + "-" + location.longitude}
						position={
							{
								lat: parseFloat(location.latitude),
								lng: parseFloat(location.longitude)
							}
						}
						icon = {myIcon}
						onClick={onClickMarker}
						title={location.title}
					/>)
				})
			)}
		</GoogleMap>
	) : <></>
})

export default GMap;

