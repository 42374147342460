import React, { Fragment } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth/ProvideAuth'

import './css/Header.css';
  
function handleLogout(e, auth, history) {
	if (!window.confirm("Confermare il logout?"))
		return;
	e.stopPropagation();
	e.nativeEvent.stopImmediatePropagation();
	auth.signout();
	history.replace('/');
}

function goBack(e, history) {
	history.goBack();
}
function goForward(e, history) {
	history.goForward();
}
  
function Header(props) {
	let auth = useAuth();
	let history = useHistory();
	let location = useLocation();
	let homePath = "/";
	
	if (!auth) {
		history.replace('/');
	}

	if (auth.solUser) {
		switch (auth.solUser.roles[0].name) {
			case 'administrator':
				break;
			case 'supplier':
				homePath = '/homeSupplier';
				break;
			case 'customer':
				homePath = '/homeCustomer';
				break;
			default:
				homePath = "/";
		}
	}
	
	return (
		<div className="container-fluid roundWhite shadow">
			<div className="container">
				<div className="header-container d-flex align-items-center justify-content-between border-bottom round bRed shadow">
					<div className="logo m-2 pt-1 text-center">
							{location.pathname.localeCompare("/") !== 0 ? (
								<Fragment>
									<span className="textWhite cliccable" onClick={ e=> goBack(e, history)}>
										<i className="textWhite fa fa-arrow-circle-o-left fa-2x" style={{'marginLeft': '4px'}} aria-hidden="true"></i>
									</span>
									<span className="textWhite cliccable" onClick={ e=> goForward(e, history)}>
										<i className="textWhite fa fa-arrow-circle-o-right fa-2x" style={{'marginLeft': '10px'}} aria-hidden="true"></i>
									</span>
								</Fragment>
									
							) : (
								<h5 className="textWhite" style={{'fontWeight':'bold'}}	dangerouslySetInnerHTML={{ __html: props.appName }}></h5>
							)}
					</div>
					<nav id="navbar" className="navbar m-1">	
						{location.pathname.localeCompare("/") !== 0 &&  !location.pathname.startsWith("/home") && (
							<Link to={ homePath }>
								<i className="fa fa-home text-white m-1 fa-2x" aria-hidden="true"></i>
							</Link>
						)}	
						{auth.solUser !== null && (
								<span className="textWhite cliccable" onClick={ e=> handleLogout(e, auth, history)}>
									<i className="fa fa-sign-out text-white m-1 fa-2x" aria-hidden="true"></i>
								</span>
						)}
						{auth.solUser === null && location.pathname.localeCompare("/login") !== 0 && (
							<Link to="/login">
								<i className="fa fa-sign-in text-white m-1 fa-2x" aria-hidden="true"></i>
							</Link>
						)}
					</nav>
				</div>
				<div className="header-container align-items-center justify-content-between">
					{auth.solUser != null && (
						<h4 className="text-center mt-2 mb-0">
							Ciao {auth.solUser.name}
							{auth.solUser.supplier && (
									" [" + auth.solUser.supplier.brand + "]"
							)}
						</h4>
					)}
				</div>				
			</div>
		</div>
	);
}

export default Header;