import React, { useState } from 'react';
import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import './css/CurrentRentDetails.css';
import bikePreloader from '../images/bike_preloader.gif'
import DisplayFiles from '../Components/DisplayFiles'
import Moment from 'react-moment';
import GMap from '../Components/GMap';

function CurrentRentDetails(props) {
	const [revoked, setRevoked] = useState(false)

	let rental = null;
	let discounted = null;
	if (props.rental && props.rental.rental) {
		rental = props.rental.rental;
	}

	if (props.rental && (props.rental.discountedPrice || props.rental.discountedPrice === 0)) {
		discounted = "oldPrice";
	}
	const onCloseModal = () => {
		if (!revoked) {
			if (window.confirm('Vuoi annullare la richiesta di noleggio?')) {
				props.callback('REVOKE');
				setRevoked(true);
				alert('Richiesta inviata.... attendere');
			}
		}
	};

	const onCloseModalNoRent = () => {
		if (window.confirm('Confermi la chiusura della schermata riassuntiva?')) {
			props.stopCallback();
		}
	}

	const sendCommand = (code) => {
		switch (code) {
		case 'END':
			if (window.confirm('Confermi di voler terminare ora il noleggio?')) {
				props.callback('END');
				alert('Richiesta inviata.... attendere');
			}
			break;
		case 'OFF':
			if (window.confirm('ATTENZIONE: spegnere l\'' + props.veicle + ' ti impedirà di utilizzare il motore ed il computer di bordo\n Sei sicuro di volerla disattivare?')) {
				props.callback('OFF');
				alert('Richiesta inviata.... attendere');
			}
			break;
		case 'ON':
			if (window.confirm('Questa funzione va utilizzata solo in caso di disalimentazione dovuta a problemi o soste prolungate.\nConfermare la riattivazione?')) {
				props.callback('ON');
				alert('Richiesta inviata.... attendere');
			}
			break;
		default:
			//NOP
		}
	};
	return (
		<>
			<>
			{(props.rental && props.rental.rental && props.rental.status.localeCompare('WAIT_START') === 0) && (
				<Modal open={true} onClose={onCloseModal}>
					<div className="card-body text-center">
						<div className="row text-center">
							<div className="col">
								<div style={{color: "#02093D"}}>
									<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
								</div>
								<h2 className="pt-3 pb-2">Il tuo Noleggio è in attesa di conferma</h2>
								<div className="row" style={{'fontSize': '1.8em'}}>
									<p>
										<strong>Modello</strong><br />{props.rental.rental.bike.size.description}
									</p>
									<p>
										<strong>Attivazione</strong><br />
										{props.rental.rental.activation_public_price ? (
											<span>{props.rental.rental.activation_public_price.toFixed(2)} €</span>
										) : (
											<span>0,00 €</span>
										)}
									</p>
									<p>
										{props.rental.rental.rent_unit_public_price && (
											<span><strong>Costo al minuto</strong><br />{(props.rental.rental.rent_unit_public_price/100).toFixed(2)} €</span>
										)}
									</p>
									<p>
										<span><strong>Costo MAX gg</strong><br />{props.rental.rental.bike.size.max_euros_day_public.toFixed(2)} €</span>
									</p>
								</div>
							</div>
						</div>
						<div className="textRed">
							<h5>
								<strong>
									Il Costo/Minuto verrà applicato per i primi 50 minuti di ogni ora.<br />
									Eventuali promozioni o abbonamenti verranno applicati all'avvio del noleggio<br />
								</strong>
								10 minuti di ogni ora sono considerati come pausa Parcheggio gratuita
							</h5>
						</div>
					</div>
				</Modal>
			)}
			</>
			<>
			{(props.rental && props.rental.rental && (props.rental.status.localeCompare('GO') === 0 || props.rental.status.localeCompare('WAIT_STOP') === 0)) && (
				<>
					<div className="container pt-4 pb-3">
						<p style={{'textAlign':'center',  'marginTop':'-1.2em', 'fontSize': '1.8em'}}><strong>Modello {props.veicle}</strong> <br /> {rental.bike.size.description}</p> -
						<p style={{'textAlign':'center', 'marginTop':'-1.2em', 'fontSize': '1.8em'}}><strong>Numero {props.veicle}</strong> <br /> {rental.bike.bike_number}</p>
						<div className="table-responsive">
							<table className="table shadow">
								<thead className="bckRed text-white">
									<tr>
										<th style={{'padding':'12px',}} scope="col">Concedente</th>
										<th style={{'padding':'12px',}} scope="col">N.Telefono</th>
										<th style={{'padding':'12px',}} scope="col">Città</th>
									</tr>
								</thead>
								<tbody>
									<tr>
									  <td style={{'padding':'12px',}}>{rental.supplier.brand}</td>
									  <td style={{'padding':'12px',}}><a href={`tel: ${rental.supplier.phone}`}>{rental.supplier.phone}</a></td>
									  <td style={{'padding':'12px',}}>{rental.supplier.city} ({rental.supplier.zip_area})</td>
									</tr>
								</tbody>
							</table>
						</div>
						<p style={{'textAlign': 'center'}}>
							I dati visualizzati sono indicativi: fare sempre riferimento ai dati definitivi indicati dal fornitore.
						</p>
					</div>
					<div className="container pt-1 pb-1">
						<div className="row row-cols-1 row-cols-sm-2">
							<div className="col mb-2 text-center digital">
								<div className="card mb-3 shadow border-0" style={{maxWidth: "540px"}}>
									<div className="row g-0">
										<div className="col-md-4 pt-2" style={{'color': "#cd0017"}}>
											<i className="fa fa-money fa-3x" aria-hidden="true"></i>
										</div>
										<div className="col-md-8">
											<div className="card-body" style={{'padding': "0px"}}>
												<p className="card-text" style={{'fontSize':'2.5em'}}>
													{(props.rental.discountedPrice != null) ? (
														<>
															<span style={{'textDecoration': 'line-through'}}>{props.rental.price.toFixed(2)} €</span>
															<br />
															{props.rental.discountedPrice.toFixed(2)} €														
														</>
													) : (
														<>
															{props.rental.price.toFixed(2)} €
														</>
													)}

												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col mb-2 text-center digital">
								<div className="card mb-3 shadow border-0" style={{maxWidth: "540px"}}>
									<div className="row g-0">
										<div className="col-md-4 pt-2" style={{'color': "#02093D"}}>
											<i className="fa fa-clock-o fa-3x" aria-hidden="true"></i>
										</div>
										<div className="col-md-8">
											<div className="card-body" style={{'padding': "0px"}}>
												<p className="card-text" style={{'fontSize':'2.5em'}}><Moment interval={1000} date={props.rental.rental.start_date} format="d HH:mm:ss" durationFromNow /></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container pb-2">
						<div className="row my-3">
							<div className="col my-2 pb-1 pb-4" style={{'textAlign': 'center'}}>
								<button disabled={props.commandsDisabled} type="button" style={{'width': '100%', 'fontSize':'2em'}} className="btn btn-primary py-3 buttonRed" onClick={(e) => sendCommand('END')}>Termina Noleggio</button>
							</div>
							<div>
								<p style={{'textAlign':'center', 'fontSize': '1.8em'}}><strong>Punti NOLEGGIO e RICARICA</strong></p>
								<GMap locations={props.locations} type="publicListSuppliers"/>
							</div>
							<div className="text-center"><h5><strong>ATTENZIONE</strong><br />I seguenti pulsanti di servizio sono da utilizzare solo in caso di Parcheggio prolungato</h5></div>
							<div className="col-md-6 my-2" style={{'textAlign': 'center'}}>
								<button disabled={props.commandsDisabled} type="button" style={{'width': '100%', 'fontSize':'2em', 'fontWeight':'bold', 'color':'#198754', 'border':'4px solid #198754'}} className="btn py-2" onClick={(e) => sendCommand('ON')}>Accendi {props.veicle}</button>
							</div>
							<div className="col-md-6 my-2" style={{'textAlign': 'center'}}>
								<button disabled={props.commandsDisabled} type="button" style={{'width': '100%', 'fontSize':'2em', 'fontWeight':'bold', 'color':'#02093D', 'border':'4px solid #02093D'}} className="btn py-2" onClick={(e) => sendCommand('OFF')}>Spegni {props.veicle}</button>
							</div>
						</div>
						{props.rental.files && (
							<DisplayFiles files={props.rental.files} noBorder={true}/>
						)}
						<h5 className="text-muted pb-3" style={{'textAlign': 'justify', 'textJustify': 'inter-word'}}><i className="fa fa-battery-quarter" aria-hidden="true"></i>&nbsp;<strong>ATTENZIONE!</strong> <br /> Se la batteria segna il 25% ( 2 tacche ), recarsi il prima possibile presso un Punto NOLEGGIO per la Ricarica o la Sostituzione.</h5>
					</div>
				</>
			)}
			</>
			<>
			{(props.rental && props.rental.rental && props.rental.status.localeCompare('WAIT_STOP') === 0) && (
				<Modal open={true} closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false}>
					<div className="text-center pt-2">
						<h4>Il tuo Noleggio è in attesa di conclusione da parte del Concedente</h4>
						<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
						<p className="text-muted">Attenzione: questo comando non blocca i conteggi del noleggio.<br />Recati presso il Punto Noleggio per completare la procedura</p>
					</div>
				</Modal>
			)}
			</>
			<>
			{(props.rental && props.rental.rental && props.rental.status.localeCompare('NORENT') === 0) && (
				<Modal open={true} onClose={onCloseModalNoRent}>
					<div className="text-center pt-2">
						<h1 className="pb-3 pt-3">Ecco i dati del tuo Noleggio</h1>
						<div className="col mb-2 text-center digital" style={{'fontSize': '2em'}}>
							<div className="card mb-3 shadow border-0">
								<p className="mt-3"><strong>Durata</strong> <br /> {props.rental.duration.d}  gg - {props.rental.duration.h}:{props.rental.duration.i}:{props.rental.duration.s}</p>
								<p className="mb-0"><strong>Importo</strong> <br />
									<span className={discounted}>{props.rental.price ? props.rental.price.toFixed(2) : props.rental.price} €</span>
								</p>
								{discounted && (
									<p className="mt-0 discountedPrice">
										{props.rental.discountedPrice.toFixed(2)} €<br />
										[{props.rental.discountedPriceCause}]
									</p>
								)}
							</div>
						</div>
						<div style={{'color': 'green', 'fontSize': '2em'}}>
							<i  className="fa fa-check fa-2x" aria-hidden="true"></i>
							Noleggio terminato correttamente!
						</div>
						<p className="textRed mt-3"><strong>Non chiudere questa finestra fino al termine del pagamento,<br />per verificare l'applicazione di eventuali sconti o tariffe speciali</strong></p>
					</div>
				</Modal>
			)}
			</>
		</>
	);
}

export default CurrentRentDetails;

