import React from 'react';
import BasePage from '../Components/BasePage';
import ComingSoon from '../Components/ComingSoon';

/***** CUSTOMER *****/
function Reservation() {
	return (
		<BasePage title="Prenota la tua eBike">
			<ComingSoon/>
		</BasePage>
	);
}

export default Reservation;