import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import {APP_NAME} from "./Config/constants";

import { ProvideAuth } from './Auth/ProvideAuth'

import Header from './Components/Header'
import Footer from './Components/Footer'
import RouteManager from './Components/RouteManager'

import Login from './Pages/Login';
import OurVeicles from './Pages/OurVeicles';
import VeiclesList from './Pages/VeiclesList';
import ServicePoints from './Pages/ServicePoints';
import Signin from './Pages/Signin';
import Notices from './Pages/Notices';
import NoticesDetails from './Pages/NoticesDetails';
import WebPage from './Pages/WebPage';
import Index from './Pages/Index';

import HomeSupplier from './Pages/HomeSupplier';
import AllRentals from './Pages/AllRentals';
import SaledSubscriptions from './Pages/SaledSubscriptions';
import NewSubscription from './Pages/NewSubscription';
import BikesList from './Pages/BikesList';
import Batteries from './Pages/Batteries';
import WebAdmin from './Pages/WebAdmin';

import HomeCustomer from './Pages/HomeCustomer';
import Reservation from './Pages/Reservation';
import Rental from './Pages/Rental';
import CurrentRent from './Pages/CurrentRent';
import LatestRentals from './Pages/LatestRentals';
import Subscriptions from './Pages/Subscriptions';
import Account from './Pages/Account';

function App() {
	/*
		<RouteManager key="rental" path="/rentalMoped2" component={Rental} role="customer" veicle="eMoped" />
		<RouteManager key="rental" path="/rentalBike2" component={Rental} role="customer" veicle="eBike" />
		<RouteManager key="rental" path="/rentalScooter2" component={Rental} role="customer" veicle="eScooter" />
	*/

	return (
		<ProvideAuth>
			<Router>
				<Header appName={APP_NAME} />
				<main className="container">
					<Route path='/*'>
						<Switch>
							<RouteManager key="login" path="/login" component={Login} role="" />

							<RouteManager key="veiclesList" path="/veiclesListEMoped" component={VeiclesList} role="" veicle="eMoped" />
							<RouteManager key="veiclesList" path="/veiclesListEBike" component={VeiclesList} role="" veicle="eBike" />
							<RouteManager key="veiclesList" path="/veiclesListEScooter" component={VeiclesList} role="" veicle="eScooter" />
							<RouteManager key="ourVeicles" path="/ourVeicles" component={OurVeicles} role="" />
							<RouteManager key="servicePoints" path="/servicePoints" component={ServicePoints} role="" />
							<RouteManager key="signin" path="/signin" component={Signin} role="" />
							<RouteManager key="notices" path="/notices" component={Notices} role="" />
							<RouteManager key="notices" path="/noticesDetails/:category" component={NoticesDetails} role="" />
							<RouteManager key="web" path="/web/:safeUrl" component={WebPage} role="" />

							<RouteManager key="homeSupplier" path="/homeSupplier" component={HomeSupplier} role="supplier" />
							<RouteManager key="allRentals" path="/allRentals" component={AllRentals} role="supplier" />
							<RouteManager key="saledSubscriptions" path="/saledSubscriptions" component={SaledSubscriptions} role="supplier" />
							<RouteManager key="newSubscription" path="/newSubscription" component={NewSubscription} role="supplier" />
							<RouteManager key="mopedList" path="/mopedList" component={BikesList} role="supplier" veicle="eMoped" />
							<RouteManager key="bikesList" path="/bikesList" component={BikesList} role="supplier" veicle="eBike" />
							<RouteManager key="scooterList" path="/scooterList" component={BikesList} role="supplier" veicle="eScooter"/>
							<RouteManager key="batteries" path="/batteries" component={Batteries} role="supplier" />
							<RouteManager key="webAdmin" path="/webAdmin" component={WebAdmin} role="supplier" />

							<RouteManager key="homeCustomer" path="/homeCustomer" component={HomeCustomer} role="customer" />
							<RouteManager key="reservation" path="/reservation" component={Reservation} role="customer" />
							<RouteManager key="rental" path="/rentalVehicle" component={Rental} role="customer" veicle="eVehicle" />
							<RouteManager key="currentRent" path="/currentRent" component={CurrentRent} role="customer" />
							<RouteManager key="latestRentals" path="/latestRentals" component={LatestRentals} role="customer" />
							<RouteManager key="subscriptions" path="/subscriptions" component={Subscriptions} role="customer" />
							<RouteManager key="account" path="/account" component={Account} role="customer" />

							<RouteManager key="/" path="/" component={Index} role="" />
							<RouteManager key="/*" path="/" component={Index} role="" />
						</Switch>
					</Route>
				</main>
				<Footer appName={APP_NAME} />
			</Router>
		</ProvideAuth>
	);
}

export default App;
