import React from 'react';
import { Link } from 'react-router-dom';
import BasePage from '../Components/BasePage';
import Card from '../Components/Card';

import { DEV } from "../Config/constants";

/***** CUSTOMER *****/
function HomeCustomer() {
	var rent_token = localStorage.getItem('rent_token');
	let badge = null;
	if (rent_token) {
		badge = "fa fa-paperclip fa-3x";
	}
	return (
		<BasePage title="La tua Home">
			<div className="row">
				<div className="col">
					<Link to="/reservation">
						<Card 
							titolo="PRENOTA"
							testo="Prenota il tuo veicolo eMobility"
							icona="fa fa-calendar fa-4x"
						/>
					</Link>
				</div>
			</div>		
			
			<div className="row">
				<div className="col">
					<Link to="/rentalVehicle">
						<Card 
							titolo="SCANSIONA il QRCode"
							testo="Attiva subito il tuo veicolo di eMobility"
							icona="fa fa-qrcode fa-4x"
							badge={badge}							
						/>
					</Link>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<Link to="/servicePoints">
						<Card
							titolo="Punti NOLEGGIO e RICARICA"
							testo="Scopri i Punti dove noleggiare e ricaricare i nostri veicoli eMobility"
							icona="fa fa-map-o fa-4x"
						/>
					</Link>
				</div>
			</div>
			
			<div className="row">
				<div className="col">
					<Link to="/latestRentals">
						<Card 
							titolo="NOLEGGI"
							testo="Elenco dei tuoi NOLEGGI"
							icona="fa fa-align-left fa-4x"
						/>
					</Link>
				</div>
			</div>

			{/* DEV ONLY */}
			{DEV && (
				<>
					<div className="row">
						<div className="col">
							<Link to="/subscriptions">
								<Card 
									titolo="ABBONAMENTI"
									testo="Elenco dei tuoi ABBONAMENTI"
									icona="fa fa-id-card-o fa-4x"
								/>
							</Link>
						</div>
					</div>
					
					<div className="row">
						<div className="col">
							<Link to="/notices">
								<Card
									titolo="EVENTI & PROMOZIONI"
									testo="Eventi e promozioni dei nostri partner"
									gIcona="mark_email_unread"
								/>
							</Link>
						</div>
					</div>					
				</>
			)}
			
			<div className="row">
				<div className="col">
					<Link to="/account">
						<Card
							titolo="ACCOUNT"
							testo="Il tuo Profilo"
							icona="fa fa-user-circle fa-4x"							
						/>
					</Link>
				</div>
			</div>
		</BasePage>
	);
}

export default HomeCustomer;