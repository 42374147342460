import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import BasePage from '../Components/BasePage';
import Api from "../Services/Api";
import QrReader from 'react-qr-reader';
import loadingGif from '../images/loading.gif'
import bikePreloader from '../images/bike_preloader.gif'
import './css/NewSubscription.css';

/***** SUPPLIER *****/
function NewSubscription() {
	const [subscriptions, setSubscriptions] = useState(null)
	const [hideQRcodeReader, setHideQRcodeReader] = useState(false)
	const [loading, setLoading] = useState(false);
	const [preloader, setPreloader] = useState(false);
	const [saveStatus, setSaveStatus] = useState(null);
	const [qrCodeDataReceived, setQrCodeDataReceived] = useState(null)
	const [customerId, setCustomerId] = useState(null)
	const [customer, setCustomer] = useState(null)
	const [customerEmail, setCustomerEmail] = useState(null)
	const [subscriptionId, setSubscriptionId] = useState(null)

	let history = useHistory();

	let duration_unit = {
		"i": "minuti",
		"h": "ore",
		"d": "giorni",
		"m": "mesi"
	};

	const handleError = err => {
		alert("Impossibile procedere con la scansione del qrcode");
	}

	const handleScan = qrCodeData => {
		if (qrCodeData) {
			setQrCodeDataReceived(qrCodeData);
			setLoading(true);
			Api.post({endpoint: 'supplier/askQRcode', body: {'rawData': qrCodeData}, contentType : 'application/json'})
				.then(response => {
					setLoading(false);
					if (response.data && response.data.customerId) {
						setCustomer(response.data.customer);
						setCustomerEmail(response.data.email);
						setSubscriptions(response.data.subscriptions);
						setCustomerId(response.data.customerId);	//Must be called at last
						setHideQRcodeReader(true);
					}
				})
				.catch(error => {
					
alert(error);
					
					setLoading(false);
					if (error.response && error.response.status === 401) {
						alert("Utente non autenticato");
						history.replace('/');
						return;
					}
					if (error.response && error.response.status === 403) {
						alert("Utente non più registrato sul sistema. Impossibile continuare");
						window.location.reload();
						return;
					}
					if (error.response && error.response.status === 409) {
						alert("Il QRCode non è stato riconosciuto. Impossibile continuare");
						window.location.reload();
						return;
					}
					if (error.response && error.response.status === 422) {
						alert("Il QRCode è già stato utilizzato. Tentativo di attivazione non autorizzato");
						window.location.reload();
						return;
					}
					alert("Si è verificato un errore imprevisto. Riprovare più tardi");
					window.location.reload();
				});
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			if (subscriptionId == null) {
				alert("Selezionare un abbonamento!")
				return;
			}

			if (!window.confirm("Confermi la vendita dell'abbonamento selezionato?")) {
				return;
			}

			setPreloader(true);
			window.scrollTo(0, 0);

			Api.post({endpoint: 'supplier/newSubscription', body: {
						'qrCodeDataReceived': qrCodeDataReceived,
						'customerId': customerId,
						'subscriptionId': subscriptionId,
					},
					contentType : 'application/json',
				})
				.then(response => {
					setLoading(false);
					setSaveStatus("OK");
				})
				.catch(error => {
					if (error.response && error.response.status === 401) {
						alert("Utente non autenticato");
						history.replace('/');
						return;
					}
					setLoading(false);
					setSaveStatus("KO")
				});
		} catch (error) {
			console.log(error);
			alert("Si è verificato un errore imprevisto. Riprovare più tardi");
			setLoading(false);
			return;
		}
	};

	const gotoBack = () => {
		history.replace('/saledSubscriptions');
	}

	const onSubscriptionChanged = e => {
		setSubscriptionId(e.currentTarget.value);
	}

	return (
		<BasePage title="Nuovo abbonamento">
			<div className="container">
				{!hideQRcodeReader && (
					<div className="text-center">
						<QrReader
							delay={500}
							onError={handleError}
							onScan={handleScan}
							style={{ width: '25%', margin: '0 auto' }}
						/>
						<strong>
							Scansiona il QRcode sulla pagina 'Abbonamenti' del cliente<br />
							ATTENZIONE: è necessario aver concesso le autorizzazioni per l'accesso alla fotocamera
						</strong>
					</div>
				)}

				{loading && (
					<div className="m-3 text-center"><img className="pl-3" src={loadingGif} alt="Loading..."/></div>
				)}

				{customerId && (
					<div className="baseOverlay">
						{preloader && (
							<div className="text-center overlay">
								<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
								<br />
								{saveStatus && (
									<>
										{saveStatus === "OK" ? (
												<p style={{'font-size': '2em', 'color': 'green'}}>Abbonamento correttamente venduto!</p>
											) : (
												<p style={{'font-size': '2em', 'color': 'red'}}>Errore imprevisto! Abbonamento non venduto</p>
											)
										}
										<button type="button" className="btn btn-primary buttonRed" onClick={(e) => gotoBack(e)}>Torna alla pagina Abbonamenti</button>
									</>
								)}
							</div>
						)}
						<form onSubmit={handleSubmit}>
							<div className="mb-3">
								<div className="form-group">
									<fieldset>
										<label htmlFor="customer">Cliente</label>
										<input
											name="customer"
											className="form-control"
											id="customer"
											maxLength="50"
											readOnly="true"
											value={customer}
										/>
									</fieldset>
								</div>
							</div>
							<div className="mb-3">
								<div className="form-group">
									<fieldset>
										<label htmlFor="customerEmail">Email</label>
										<input
											name="customerEmail"
											className="form-control"
											id="customerEmail"
											readOnly="true"
											value={customerEmail}
										/>
									</fieldset>
								</div>
							</div>
							<div className="mb-3">
								<div className="form-group">
									<fieldset>
										<label htmlFor="subscription">Abbonamento</label>
										{subscriptions.map(function(subscription, i) {
												return (
													<div className="row" style={{'border-bottom': '1px solid #E2001A'}}>
														<div className="text-center">
															<input
																key={subscription.id}
																type="radio"
																name="subscription"
																id={`ID_${subscription.id}`}
																value={subscription.id}
																onChange={onSubscriptionChanged}
															/>
														</div>
														<div className="text-center">
															<label className="mx-2" for={`ID_${subscription.id}`}>
																<strong>{subscription.name}</strong><br />
																Prezzo: 
																{subscription.discounted_public_price ? (
																	<>
																		<span style={{'textDecoration': 'line-through'}}>{subscription.public_price.toFixed(2)}€</span><br />
																		Prezzo scontato: <strong>{subscription.discounted_public_price.toFixed(2)}€</strong>
																	</>
																) : (
																	<>
																		{subscription.public_price.toFixed(2)}€
																	</>
																)}
																<br />
																Durata: {subscription.duration} {duration_unit[subscription.duration_unit]}
															</label>
														</div>
													</div>
												)
											})
										}
									</fieldset>
								</div>
							</div>
							<div className="mb-3">
								<div className="form-group">
									<fieldset>
										<button type="button" className="btn btn-primary buttonRed" onClick={(e) => handleSubmit(e)}>Vendi nuovo abbonamento</button>
									</fieldset>
								</div>
							</div>
						</form>
					</div>
				)}
			</div>
		</BasePage>
	);
}

export default NewSubscription;