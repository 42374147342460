import React from 'react';
import './css/Card.css';

function Card(props) {
	return (
		<div className="card mb-3 shadow border-0" style={{maxWidth: "540px"}}>
			{props.badge && (
				<span className="badge rounded-circle pulse">
					<i className={props.badge} aria-hidden="true"></i>
				</span>
			)}
			<div className="row g-0">
				<div className="col-md-4 p-3" style={{color: "#02093D"}}>
					{props.icona && (
						<i className={props.icona} aria-hidden="true"></i>
					)}
					{props.gIcona && (
						<span className="material-icons" style={{'fontSize': '4em'}}>{props.gIcona}</span>
					)}
				</div>
				<div className="col-md-8">
					<div className="card-body">
						<p className="card-title" style={{'fontSize': '1.3em'}}>{props.titolo}</p>
						<p className="card-text" style={{'fontSize': '1.2em'}}><span className="text-muted">{props.testo}</span></p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Card;

