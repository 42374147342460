import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import BasePage from '../Components/BasePage';
import Api from "../Services/Api";
import './css/WebPage.css';

/***** PUBLIC *****/
function WebPage() {
	const [page, setPage] = useState({title:'', img_main:''})
	const [notFound, setNotFound] = useState(false)
	const [loading, setLoading] = useState(true)
	let { safeUrl } = useParams();

	useEffect(() => {
		Api.get({endpoint: 'service/publicSupplierWeb/'+safeUrl, contentType : 'application/json'})
			.then(response => {
				setPage(response.data);
				setLoading(false);
			})
			.catch(error => {
				if (error.response && error.response.status === 404) {
					setNotFound(true);
					setPage([]);
					setLoading(false);
				}
				else {
					alert("Non è stato possibile caricare la pagina.\nRiprovare più tardi!");
				}
			});
	}, [safeUrl]);

	const styles = {
		img_main: {
			background: `url(${page.img_main}) top center`,
		}
	};

	return (
		<BasePage title={page.title}>
			{loading ? (
				<h2 className="text-center">Caricamento in corso...</h2>
			) : (
			notFound ? (
					<div style={{'textAlign': 'center'}}>
						<p style={{'fontSize': '2em'}}>La pagina richiesta non esiste!</p>
						<img src="/images/notFoundWeb.png" alt="Not found" />
					</div>
				) : (
					page.main_text ? (
						<>
							<section id="hero" className="d-flex justify-cntent-center align-items-center" style={styles.img_main}>
								<div id="" className="container carousel carousel-fade" data-bs-ride="carousel">
									<div className="carousel-item active">
										<div className="carousel-container">
											<img src={page.img_logo} className="img-fluid img-logo" alt={page.title}/>
											<h3 className="text-white">{page.slogan}</h3>
										</div>
									</div>
								</div>
							</section>
							<main id="main">
								<div className="container icon-boxes mb-5">
									<div className="container-fluid">
										<div className="row" style={{'textAlign': 'center'}}>
											<div className="col-md-4 mt-3">
												<img src={page.img_01} className="img-fluid border-0" alt={page.title} />
											</div>
											<div className="col-md-4 mt-3">
												<img src={page.img_02} className="img-fluid border-0" alt={page.title} />
											</div>
											<div className="col-md-4 mt-3">
												<img src={page.img_03} className="img-fluid border-0" alt={page.title} />
											</div>
										</div>
									</div>
								</div>
							</main>
							<div className="container">
								<h1>L'azienda</h1>
							</div>
							<div className="container mb-5">
								<div className="row">
									<p>{page.main_text}</p>
								</div>
							</div>
							{page.prod_serv && (
								<>
									<div className="container">
										<h1>Prodotti e servizi</h1>
									</div>
									<div className="container mb-5">
										<div className="card border-0 shadow">
											<div className="card-body">
												<p>{page.prod_serv}</p>
											</div>
										</div>
									</div>
								</>
							)}
							<div className="container">
								<h1>Giorni e orari di apertura</h1>
							</div>
							<div className="container mb-5">
								<div className="row">
									<p>{page.opening}</p>
								</div>
							</div>						
							<div className="webFooter">
								Tutti i contenuti testuali e fotografici visibili in questa pagina sono di esclusiva responsabilità del concedente.<br />
								SPORT ON LEASE non è responsabile per eventuali contenuti non veritieri o inappropriati
							</div>
						</>
					) : (
						<div style={{'textAlign': 'center'}}>
							<p style={{'fontSize': '2em'}}>Ciao, la pagina è in fase di costruzione<br />Torna a trovarci!</p>
							<img src="/images/wip.png" alt="Work in progress" />
						</div>
					)
				)
			)}
		</BasePage>
	);
}

export default WebPage;
