export const DEV = process.env.REACT_APP_DEV === "true" ? true : false
export const MAIN_SITE = process.env.REACT_APP_MAIN_SITE
export const API_URL = process.env.REACT_APP_API_URL
export const APP_NAME = process.env.REACT_APP_APP_NAME
export const MAPS_KEY = process.env.REACT_APP_MAPS_KEY
export const MAPS_CIRCLE_RADIUS = process.env.REACT_APP_MAPS_CIRCLE_RADIUS
export const MAPS_INIT_ZOOM = process.env.REACT_APP_MAPS_INIT_ZOOM
export const MAPS_CIRCLE_MARKER = process.env.REACT_APP_MAPS_CIRCLE_MARKER
export const MAPS_DUMMY_MARKER = process.env.REACT_APP_MAPS_DUMMY_MARKER
export const MAPS_MAX_ACCURACY = process.env.REACT_APP_MAPS_MAX_ACCURACY
export const GOOGLE_MAP_LINK = process.env.REACT_APP_GOOGLE_MAP_LINK
export const VERSION = process.env.REACT_APP_VERSION
