import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Moment from 'react-moment';
import BasePage from '../Components/BasePage';
import Api from "../Services/Api";
import bikePreloader from '../images/bike_preloader.gif'
import './css/AllRentals.css';
import { Modal } from "react-responsive-modal";
import { useFormik } from 'formik';
import * as Yup from 'yup';

/***** SUPPLIER *****/
function AllRentals() {
	const [apiResponse, setApiResponse] = useState(null)
	const [apiDetails, setApiDetails] = useState(null)
	const [commandsDisabled, setCommandsDisabled] = useState(false)
	const [hiddenPrivatePrice, setHiddenPrivatePrice] = useState(true)
	const [modalOpen, setModalOpen] = useState(false)

	let history = useHistory();

	let today = new Date();
	let pastDay = new Date(today.getFullYear(), today.getMonth(), 2);
	
	today = today.toISOString().substring(0, 10);
	pastDay = pastDay.toISOString().substring(0, 10);
	
	const onCloseModal = () => {
		setModalOpen(false);
		setApiDetails(null);
	};

	const formik = useFormik({
		initialValues: {
			from_date: pastDay,
			to_date: today,
		},
		validationSchema: Yup.object({
			from_date:
				Yup.date()
				.required('Campo obbligatorio'),
			to_date:
				Yup.date()
				.required('Campo obbligatorio'),
		}),
		onSubmit: values => {
			refreshPage()
		},
	});

	const setApiData = (rent_token, status, textBtn, secondaryBtn, e_personal_rent) => {
		let code = null;
		let personal_rent = e_personal_rent;

		if (status.localeCompare("GO") === 0) {
			if (!secondaryBtn) {
				if (!personal_rent) {
					if (!window.confirm("ATTENZIONE: Questa operazione blocca in modo IMMEDIATO e senza preavviso il noleggio\ne disabilita l'alimentazione alla eBike\nContinuare?"))
						return;
					if (!window.confirm("Si vuole procedere con il KILL del noleggio ADESSO?"))
						return;
				}
				else {
					if (!window.confirm("Confermare l'operazione [" + textBtn + "] adesso?"))
						return;
				}
				code = status;
			}
			else {
				code = "DETAILS";
			}
		}
		else {
			if (!secondaryBtn) {
				if (!window.confirm("Confermare l'operazione [" + textBtn + "] adesso?"))
					return;

				code = status;
			}
			else {
				if (status.localeCompare("WAIT_START") === 0) {
					if (!window.confirm("Confermare l'operazione [" + textBtn + "] adesso?"))
						return;
					code = "CANCEL";
				}
				else {
					code = "DETAILS";
				}
			}
		}
		
		if (code.localeCompare("DETAILS") === 0) {
			if (status.localeCompare("WAIT_STOP") !== 0 && status.localeCompare("GO") !== 0)
			{
				alert("Funzione disponibile solo per noleggi in corso!");
				return;
			}

			setModalOpen(true);
			getApiDetails(rent_token);
		}
		else {
			setCommandsDisabled(true);
			setApiResponse(null);

			Api.get({endpoint: 'supplier/updateRent/'+rent_token+'/'+code, contentType : 'application/json'})
				.then(response => {
					switch (code) {
						case 'WAIT_START':
							if (response.status && response.status === 228) {
								alert("L'eBike non è raggiungibile.\nSpostarla e riprovare");
								break;
							}
							if (response.status && response.status === 224) {
								alert("Impossibile localizzare correttamente l'eBike.\nSpostarla e riprovare");
								break;
							}
							if (response.status && response.status === 223) {
								alert('L\'eBike risulta già accesa!');
								break;
							}
							if (response.status && response.status === 200) {
								alert('Noleggio attivato!');
								break;
							}
							alert('Impossibile eseguire l\'operazione\nRivolgiti all\'assistenza per supporto');
							break;
						case 'GO':
							if (!personal_rent)
								alert('Noleggio disattivato in modo forzato\nKILL!');
							else
								alert('Noleggio disattivato!');
							break;
						case 'CANCEL':
							alert('Prenotazione annullata\nCANCEL!');
							break;
						case 'WAIT_STOP':
							alert('Noleggio disattivato!');
							break;
						case 'PAY':
							alert('Modalità di pagamento modificata!');
							break;
						default:
							alert('Impossibile eseguire l\'operazione\nRivolgiti all\'assistenza per supporto');
					}
					getApiData();
					setCommandsDisabled(false);
				})
				.catch(error => {
					setCommandsDisabled(false);
					if (error.response && error.response.status === 401) {
						alert("Utente disabilitato");
						history.replace('/');
						return;
					}
					if (error.response && error.response.status === 403) {
						alert("Token non valido");
						history.replace('/');
						return;
					}
					if (error.response && error.response.status === 409) {
						alert("Il noleggio è stato revocato durante la fase di attivazione.\nNoleggio non attivato");
						getApiData();
						return;
					}
					if (error.response && error.response.status === 224) {
						alert("Impossibile localizzare correttamente l'eBike.\nSpostarla e riprovare");
						getApiData();
						return;
					}
					alert('Impossibile eseguire l\'operazione\nRivolgiti all\'assistenza per supporto');
					getApiData();
				});
		}
	}

	const getApiDetails = (rent_token) => {
		Api.get({endpoint: 'supplier/rentDetails/'+rent_token, contentType : 'application/json'})
		.then(response => {
			var details = response.data.position;
			setApiDetails(details);
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				alert("Utente disabilitato");
				history.replace('/');
				return;
			}
			history.replace('/homeSupplier');
		});
	}

	const getApiData = () => {		
		Api.get({endpoint: `supplier/listAllRentals/${formik.values.from_date}/${formik.values.to_date}`, contentType : 'application/json'})
		.then(response => {
			var rentals = response.data;
			setApiResponse(rentals);
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				alert("Utente disabilitato");
				history.replace('/');
				return;
			}
			history.replace('/homeSupplier');
		});
	}

	const refreshPage = () => {
		getApiData();
	}

	const handlePrivateVisibility = (e) => {
		setHiddenPrivatePrice(e.target.checked);
	}

	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<BasePage title="Elenco noleggi">
			{apiResponse ? (
				<>
					<div className="container pt-2 pb-5">
						<div className="row mb-3">
							<div className="col">
								<div className="row">
									<div className="col">
										<label htmlFor="from_date" className="form-label required">Inizio richiesta noleggio da:</label>
									</div>
									<div className="col">
										<input
											id="from_date"
											name="from_date"
											type="date"
											className="form-control"
											style={{width: 'auto', marginLeft: '10px'}}
											aria-label="Data inizio"
											{...formik.getFieldProps('from_date')}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<label htmlFor="to_date" className="form-label required">Inizio richiesta noleggio a:</label>
									</div>
									<div className="col">
										<input
											id="to_date"
											name="to_date"
											type="date"
											className="form-control"
											style={{width: 'auto', marginLeft: '10px'}}
											aria-label="Data fine"
											{...formik.getFieldProps('to_date')}
										/>
									</div>
								</div>
							</div>
							<div className="col">
								<button className="btn btn-primary buttonRed" onClick={refreshPage} disabled={commandsDisabled}>Aggiorna la pagina <i className="fa fa-refresh" aria-hidden="true"></i></button>
							</div>
							<div className="col" style={{'fontSize': '1.5em', 'textAlign': 'right'}}>
								<input id="privatePrices" type="checkbox" className="mx-2" checked={hiddenPrivatePrice} onChange={(e) => handlePrivateVisibility(e)} />
								<label htmlFor="privatePrices">Nascondi i prezzi interni</label>
							</div>
						</div>
						<div className="table-responsive">
							<table className="table shadow">
								<thead className="bckRed text-white">
									<tr>
										<th scope="col">N.eBike</th>
										<th scope="col">Modello</th>
										<th scope="col">Cliente</th>
										<th scope="col">Data di nascita</th>
										<th scope="col">Telefono</th>
										<th scope="col">Data richiesta inizio</th>
										<th scope="col">Data effettiva inizio</th>
										<th scope="col">Data richiesta fine</th>
										<th scope="col">Data effettiva fine</th>
										<th scope="col">Durata</th>
										<th scope="col">Costo al pubblico</th>
										<th scope="col">Costo interno</th>
										<th scope="col">Extra sconto</th>
										<th scope="col">Noleggio personale</th>
										<th scope="col">Chiusura</th>
										<th scope="col">Sconto</th>
										<th scope="col" className="text-center" colSpan="2">Comandi</th>
									</tr>
								</thead>
								<tbody>
									{
										apiResponse.rentals.map(function(rent, i){
											let btnText = null;
											let btnText2 = null;
											let alertAge = false;
											let pulseBike = false;
											switch (rent.status) {
												case 'WAIT_START':
													btnText = "ATTIVA Noleggio"
													btnText2 = "ANNULLA Prenotazione"
													alertAge = true;
													pulseBike = true;
													break;
												case 'GO':
													if (rent.personal_rent)
														btnText = "DISATTIVA Noleggio"
													else
														btnText = "KILL Noleggio"
													btnText2 = "Altri dettagli"
													alertAge = true;
													break;
												case 'WAIT_STOP':
													btnText = "DISATTIVA Noleggio"
													btnText2 = "Altri dettagli"
													alertAge = true;
													pulseBike = true;
													break;
												case 'NORENT':
													btnText = null
													btnText2 = "Altri dettagli"
													break;
												default:
													btnText = null
													btnText2 = null
											}

											var bikeClass = "fa fa-bicycle fa-2x " + rent.status.toLowerCase();
											if (pulseBike)
												bikeClass += " fa-pulse";
											var ageStyle = {};
											if (rent.customer_age < 18 && alertAge) {
												ageStyle.color = 'red';
												ageStyle.fontWeight = 'bold';
											}
											var rowColor = "inherit";
											if (rent.personal_rent)
												rowColor = "var(--bs-primary)";
											return (
												<tr key={rent.rent_token} style={{'color': rowColor}}>
													<td>
														{rent.bike_number}<br />
														<i className={bikeClass} aria-hidden="true"></i>
													</td>
													<td>{rent.bikeModel}</td>
													<td>{rent.customer}</td>
													<td style={ageStyle}>
														<Moment format="DD/MM/YYYY">{rent.customer_birth_date}</Moment>
														{rent.customer_age < 18 && (
															<>
																<br />
																{rent.customer_age}
																anni
															</>
														)}
													</td>
													<td>{rent.customer_phone}</td>
													<td><Moment format="DD/MM/YYYY HH:mm:ss">{rent.request_start_date}</Moment></td>
													<td><u>{rent.start_date ? <Moment format="DD/MM/YYYY HH:mm:ss">{rent.start_date}</Moment> : ''}</u></td>
													<td>{rent.request_stop_date ? <Moment format="DD/MM/YYYY HH:mm:ss">{rent.request_stop_date}</Moment> : ''}</td>
													<td><u>{rent.stop_date ? <Moment format="DD/MM/YYYY HH:mm:ss">{rent.stop_date}</Moment> : ''}</u></td>
													<td>{rent.duration}</td>
													<td>
														{!rent.tot_public_discounted_price ? (
															<>
																{rent.tot_public_price}
															</>
														):(
															<>
																<span style={{'textDecoration': 'line-through'}}>{rent.tot_public_price}</span>
																<br />
																<span style={{'fontWeight': 'bold'}}>{rent.tot_public_discounted_price}</span>
															</>
														)}
													</td>
													<td>
														{hiddenPrivatePrice ? (
															"***.**"
														):(
															<>
																{!rent.tot_private_discounted_price ? (
																	<>
																	{rent.tot_private_price}
																	</>
																):(
																	<>
																		<span style={{'textDecoration': 'line-through'}}>{rent.tot_private_price}</span>
																		<br />
																		{rent.tot_private_discounted_price}
																	</>
																)}
															</>
														)}
													</td>
													<td>
														<span>
														{
															rent.discount === 1 ?
																<i className="fa fa-check-square-o" aria-hidden="true"></i>
															:
																<i className="fa fa-square-o" aria-hidden="true"></i>
														}
														</span>
													</td>
													<td>{rent.personal_rent === 1 ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</td>
													<td>{rent.exit_code}</td>
													<td>{rent.discount_code}</td>
													<td>
														{btnText && (
															<button onClick={() => setApiData(rent.rent_token, rent.status, btnText, false, rent.personal_rent)} className="btn btn-primary buttonRed" disabled={commandsDisabled}>{btnText}</button>
														)}
													</td>
													<td>
														{btnText2 && (
															<button onClick={() => setApiData(rent.rent_token, rent.status, btnText2, true, rent.personal_rent)} className="btn btn-primary btn_secondary_function" disabled={commandsDisabled}>{btnText2}</button>
														)}
													</td>
												</tr>
											)
										})
									}
									<tr key="totals">
										<td colSpan="10">
											<h2><strong>Importi totali per il periodo selezionato</strong></h2>
										</td>
										<td>
											<h3>
												<span style={{'textDecoration': 'line-through'}}>{apiResponse.totals.tot_public_price}</span>
												<br />
												<span style={{'fontWeight': 'bold'}}>{apiResponse.totals.tot_public_discounted_price}</span>
											</h3>
										</td>
										<td>
											{hiddenPrivatePrice ? (
												"***.**"
											):(
												<h3>
													<span style={{'textDecoration': 'line-through'}}>{apiResponse.totals.tot_private_price}</span>
													<br />
													{apiResponse.totals.tot_private_discounted_price}
												</h3>
											)}
										</td>
										<td colSpan="6"></td>
									</tr>	
								</tbody>
							</table>														
						</div>
					</div>
				</>
			):(
				<div className="text-center">
					<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
				</div>
			)}
			<Modal open={modalOpen} onClose={onCloseModal}>
				{apiDetails ? (
					<div>
						<h2>Dettagli e stato della eBike</h2>
						<ul>
							<li>Batteria inserita: {apiDetails.batteryConnected === "false" ? "NO" : "SI"}</li>
							<li>Modello: {apiDetails.bikeModel}</li>
							<li>N.eBike: {apiDetails.bike_number}</li>
							<li>Ultimo posizionamento: {apiDetails.gpsTime}</li>
							<li>Ultimo contatto: {apiDetails.hbTime}</li>
							<li>Posizione attuale: <a target="_blank" href={apiDetails.mapLink} rel="noreferrer">&nbsp;&nbsp;<i className="fa fa-map-marker cliccable" aria-hidden="true"></i></a></li>
						</ul>
					</div>
				):(
					<div className="text-center">
						<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
					</div>
				)}
			</Modal>
		</BasePage>
	);
}

export default AllRentals;