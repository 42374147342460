import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import BasePage from '../Components/BasePage';
import Api from "../Services/Api";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import bikePreloader from '../images/bike_preloader.gif'
import loadingGif from '../images/loading.gif'
import './css/WebAdmin.css';

/***** SUPPLIER *****/
function WebAdmin() {
	const [apiResponse, setApiResponse] = useState(null)
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [changedImg_logo, setChangedImg_logo] = useState(null);
	const [changedImg_main, setChangedImg_main] = useState(null);
	const [changedImg_01, setChangedImg_01] = useState(null);
	const [changedImg_02, setChangedImg_02] = useState(null);
	const [changedImg_03, setChangedImg_03] = useState(null);

	let history = useHistory();
	let changedImgStyle = {border: '1px solid red', opacity: 0.5};
	
	const clickImage = (event) => {
		let newApiResponse = { ...apiResponse };

		newApiResponse.slogan = document.getElementById('slogan').value;
		newApiResponse.main_text = document.getElementById('main_text').value;
		newApiResponse.prod_serv = document.getElementById('prod_serv').value;
		newApiResponse.opening = document.getElementById('opening').value;

		switch (event.target.name) {
			case 'img_logo':
				formik.setFieldValue("img_logo", event.currentTarget.files[0]);
				setChangedImg_logo(changedImgStyle);
				newApiResponse.img_logo_thumb = URL.createObjectURL(event.target.files[0]);
				newApiResponse.img_logo_dummy = event.currentTarget.files[0];
				break;
			case 'img_main':
				formik.setFieldValue("img_main", event.currentTarget.files[0]);
				setChangedImg_main(changedImgStyle);
				newApiResponse.img_main_thumb = URL.createObjectURL(event.target.files[0]);
				newApiResponse.img_main_dummy = event.currentTarget.files[0];
				break;
			case 'img_01':
				formik.setFieldValue("img_01", event.currentTarget.files[0]);
				setChangedImg_01(changedImgStyle);
				newApiResponse.img_01_thumb = URL.createObjectURL(event.target.files[0]);
				newApiResponse.img_01_dummy = event.currentTarget.files[0];
				break;
			case 'img_02':
				formik.setFieldValue("img_02", event.currentTarget.files[0]);
				setChangedImg_02(changedImgStyle);
				newApiResponse.img_02_thumb = URL.createObjectURL(event.target.files[0]);
				newApiResponse.img_02_dummy = event.currentTarget.files[0];
				break;
			case 'img_03':
				formik.setFieldValue("img_03", event.currentTarget.files[0]);
				setChangedImg_03(changedImgStyle);
				newApiResponse.img_03_thumb = URL.createObjectURL(event.target.files[0]);
				newApiResponse.img_03_dummy = event.currentTarget.files[0];
				break;
			default:
				return;
		}
		setApiResponse(newApiResponse);
	}

	const formik = useFormik({
		initialValues: {
			slogan: apiResponse?.slogan ?? "",
			main_text: apiResponse?.main_text ?? "",
			prod_serv: apiResponse?.prod_serv ?? "",
			opening: apiResponse?.opening ?? "",
			img_logo: apiResponse?.img_logo_dummy ?? "",
			img_main: apiResponse?.img_main_dummy ?? "",
			img_01: apiResponse?.img_01_dummy ?? "",
			img_02: apiResponse?.img_02_dummy ?? "",
			img_03: apiResponse?.img_03_dummy ?? "",
			img_logo_fileName: apiResponse?.img_logo_thumb ?? "",
			img_main_fileName: apiResponse?.img_main_thumb ?? "",
			img_01_fileName: apiResponse?.img_01_thumb ?? "",
			img_02_fileName: apiResponse?.img_02_thumb ?? "",
			img_03_fileName: apiResponse?.img_03_thumb ?? "",
		},
		validate : values => {
            let errors = {}

            if( !values.img_logo_fileName && !values.img_logo ){
                errors.img_logo = "Seleziona il logo"
            }
            if( !values.img_main_fileName && !values.img_main ){
                errors.img_main = "Seleziona lo sfondo"
            }
            if( !values.img_01_fileName && !values.img_01 ){
                errors.img_01 = "Seleziona il riquadro 01"
            }
            if( !values.img_02_fileName && !values.img_02 ){
                errors.img_02 = "Seleziona il riquadro 02"
            }
            if( !values.img_03_fileName && !values.img_03 ){
                errors.img_03 = "Seleziona il riquadro 03"
            }
            return errors
        },
		validationSchema: Yup.object({
			slogan:
				Yup.string()
				.max(100, 'Lunghezza massima 100 caratteri'),
			main_text:
				Yup.string()
				.max(1500, 'Lunghezza massima 1500 caratteri')
				.required('Campo obbligatorio'),
			prod_serv:
				Yup.string()
				.max(1500, 'Lunghezza massima 1500 caratteri'),
			opening:
				Yup.string()
				.max(255, 'Lunghezza massima 1500 caratteri')
				.required('Campo obbligatorio'),
			img_logo:
				Yup.mixed()
				.nullable()
				.test('fileSize', 'File troppo grande', (value) => value === undefined || value === null || (value && value.size/1024/1024 <= 2)),
			img_main:
				Yup.array()
				.nullable()
				.test('fileSize', 'File troppo grande', (value) => value === undefined || value === null || (value && value.size/1024/1024 <= 2)),
			img_01:
				Yup.array()
				.nullable()
				.test('fileSize', 'File troppo grande', (value) => value === undefined || value === null || (value && value.size/1024/1024 <= 2)),
			img_02:
				Yup.array()
				.nullable()
				.test('fileSize', 'File troppo grande', (value) => value === undefined || value === null || (value && value.size/1024/1024 <= 2)),
			img_03:
				Yup.array()
				.nullable()
				.test('fileSize', 'File troppo grande', (value) => value === undefined || value === null || (value && value.size/1024/1024 <= 2)),
		}),
		onSubmit: values => {
			let formData = new FormData();
			formData.append('slogan', values.slogan);
			formData.append('main_text', values.main_text);
			formData.append('prod_serv', values.prod_serv);
			formData.append('opening', values.opening);

			formData.append('img_logo_fileName', values.img_logo_fileName);
			formData.append('img_main_fileName', values.img_main_fileName);
			formData.append('img_01_fileName', values.img_01_fileName);
			formData.append('img_02_fileName', values.img_02_fileName);
			formData.append('img_03_fileName', values.img_03_fileName);

			values.img_logo && formData.append('img_logo', values.img_logo);
			values.img_main && formData.append('img_main', values.img_main);
			values.img_01 && formData.append('img_01', values.img_01);
			values.img_02 && formData.append('img_02', values.img_02);
			values.img_03 && formData.append('img_03', values.img_03);

			setLoading(true);
			try {
				Api.post({endpoint: 'supplier/webAdmin', body: formData, contentType : 'multipart/form-data'})
					.then(response => {
						let newApiResponse = { ...apiResponse };
						newApiResponse.preview = response.data.preview;
						setApiResponse(newApiResponse);

						setDisplayAlert(1);
						let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);

						setChangedImg_logo(null);
						setChangedImg_main(null);
						setChangedImg_01(null);
						setChangedImg_02(null);
						setChangedImg_03(null);
					})
					.catch(error => {
						if (error.response && error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						console.log(error);
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});

	const getApiData = () => {
		Api.get({endpoint: 'supplier/webAdmin', contentType : 'application/json'})
		.then(response => {
			setApiResponse(response.data.data);
		})
		.catch(error => {
			console.log(">>>" + error);
			alert("Problemi nel recupero della pagina");
			history.replace('/homeSupplier');
		});
	}

	const askDelete = () => {
		if (!window.confirm("Confermare l'eliminazione dell'intera pagina e dei suoi contenuti?"))
			return;
		setLoading(true);
		Api.del({endpoint: 'supplier/webAdmin'})
			.then(response => {
				window.alert("Pagina correttamente eliminata");
				history.replace('/homeSupplier');
			})
			.catch(error => {
				setDisplayAlert(-1);
			});
	}
	
	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<BasePage title="Gestione pagina aziendale">
			{apiResponse ? (
				<>
					<div className="container pt-5 pb-5">
						<div style={{'textAlign':'left'}}>
							<form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
								<div className="row">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="slogan" className="form-label">Slogan</label>
											<input
												disabled={loading}
												id="slogan"
												name="slogan"
												type="text"
												className="form-control"
												placeholder="Slogan"
												aria-label="Slogan"
												{...formik.getFieldProps('slogan')}
											/>
											{formik.touched.slogan && formik.errors.slogan ? (
												<div className="validationMessage">{formik.errors.slogan}</div>
											) : null}
										</fieldset>
									</div>
								</div>
								<div className="row pt-3">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="main_text" className="form-label required">L'azienda</label>
											<textarea
												disabled={loading}
												id="main_text"
												name="main_text"
												className="form-control"
												placeholder="L'azienda"
												aria-label="L'azienda"
												{...formik.getFieldProps('main_text')}
											/>
											{formik.touched.main_text && formik.errors.main_text ? (
												<div className="validationMessage">{formik.errors.main_text}</div>
											) : null}
										</fieldset>
									</div>
								</div>
								<div className="row pt-3">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="prod_serv" className="form-label">Prodotti e servizi</label>
											<textarea
												disabled={loading}
												id="prod_serv"
												name="prod_serv"
												type="text"
												className="form-control"
												placeholder="Prodotti e servizi"
												aria-label="Prodotti e servizi"
												{...formik.getFieldProps('prod_serv')}
											/>
											{formik.touched.prod_serv && formik.errors.prod_serv ? (
												<div className="validationMessage">{formik.errors.prod_serv}</div>
											) : null}
										</fieldset>
									</div>
								</div>
								<div className="row pt-3">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="prod_serv" className="form-label required">Giorni e orari di apertura</label>
											<input
												disabled={loading}
												id="opening"
												name="opening"
												type="text"
												className="form-control"
												placeholder="Giorni e orari di apertura"
												aria-label="Giorni e orari di apertura"
												{...formik.getFieldProps('opening')}
											/>
											{formik.touched.opening && formik.errors.opening ? (
												<div className="validationMessage">{formik.errors.opening}</div>
											) : null}
										</fieldset>
									</div>
								</div>
								<div className="row pt-3">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="img_logo" className="form-label required">Logo (immagine jpg/png - max 2Mb - Formato {apiResponse.img_logo_size})</label>
											<input
												disabled={loading}
												id="img_logo"
												name="img_logo"
												type="file"
												className="form-control"
												onChange={clickImage}
											/>
											<input id="img_logo_fileName" name="img_logo_fileName" type="hidden" {...formik.getFieldProps('img_logo_fileName')} />
											{formik.touched.img_logo && formik.errors.img_logo ? (
												<div className="validationMessage">{formik.errors.img_logo}</div>
											) : null}
										</fieldset>
									</div>
									{apiResponse.img_logo_thumb && (
										<div className="col-md-12 form-group">
											<img src={apiResponse.img_logo_thumb} className="thumb tumb_logo" style={changedImg_logo} alt="Logo" />
										</div>
									)}
								</div>
								<hr />
								<div className="row pt-3">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="img_main" className="form-label required">Sfondo (immagine jpg/png - max 2Mb - Formato {apiResponse.img_main_size})</label>
											<input
												disabled={loading}
												id="img_main"
												name="img_main"
												type="file"
												className="form-control"
												onChange={clickImage}
											/>
											<input id="img_main_fileName" name="img_main_fileName" type="hidden" {...formik.getFieldProps('img_main_fileName')} />
											{formik.touched.img_main && formik.errors.img_main ? (
												<div className="validationMessage">{formik.errors.img_main}</div>
											) : null}
										</fieldset>
									</div>
									{apiResponse.img_main_thumb && (
										<div className="col-md-12 form-group">
											<img src={apiResponse.img_main_thumb} className="thumb tumb_main" style={changedImg_main} alt="Sfondo" />
										</div>
									)}
								</div>
								<hr />
								<div className="row pt-3">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="img_01" className="form-label required">Riquadro 01 (immagine jpg/png - max 2Mb - Formato {apiResponse.img_01_size})</label>
											<input
												disabled={loading}
												id="img_01"
												name="img_01"
												type="file"
												className="form-control"
												onChange={clickImage}
											/>
											<input id="img_01_fileName" name="img_01_fileName" type="hidden" {...formik.getFieldProps('img_01_fileName')} />
											{formik.touched.img_01 && formik.errors.img_01 ? (
												<div className="validationMessage">{formik.errors.img_01}</div>
											) : null}
										</fieldset>
									</div>
									{apiResponse.img_01_thumb && (
										<div className="col-md-12 form-group">
											<img src={apiResponse.img_01_thumb} className="thumb tumb_01" style={changedImg_01} alt="Riquadro sinistra" />
										</div>
									)}
								</div>
								<hr />
								<div className="row pt-3">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="img_02" className="form-label required">Riquadro 02 (immagine jpg/png - max 2Mb - Formato {apiResponse.img_02_size})</label>
											<input
												disabled={loading}
												id="img_02"
												name="img_02"
												type="file"
												className="form-control"
												onChange={clickImage}
											/>
											<input id="img_02_fileName" name="img_02_fileName" type="hidden" {...formik.getFieldProps('img_02_fileName')} />
											{formik.touched.img_02 && formik.errors.img_02 ? (
												<div className="validationMessage">{formik.errors.img_02}</div>
											) : null}
										</fieldset>
									</div>
									{apiResponse.img_02_thumb && (
										<div className="col-md-12 form-group">
											<img src={apiResponse.img_02_thumb} className="thumb tumb_02" style={changedImg_02} alt="Riquadro centro" />
										</div>
									)}
								</div>
								<hr />
								<div className="row pt-3">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="img_03" className="form-label required">Riquadro 03 (immagine jpg/png - max 2Mb - Formato {apiResponse.img_03_size})</label>
											<input
												disabled={loading}
												id="img_03"
												name="img_03"
												type="file"
												className="form-control"
												onChange={clickImage}
											/>
											<input id="img_03_fileName" name="img_03_fileName" type="hidden" {...formik.getFieldProps('img_03_fileName')} />
											{formik.touched.img_03 && formik.errors.img_03 ? (
												<div className="validationMessage">{formik.errors.img_03}</div>
											) : null}
										</fieldset>
									</div>
									{apiResponse.img_03_thumb && (
										<div className="col-md-12 form-group">
											<img src={apiResponse.img_03_thumb} className="thumb tumb_03" style={changedImg_03} alt="Riquadro destra" />
										</div>
									)}
								</div>
								<div className="row pt-3">
									<div className="col">
										{loading && (
												<>
													<>
													{displayAlert === 0 && (
														<img className="pl-3" src={loadingGif} alt="Loading..."/>
													)}
													</>
													<>
													{displayAlert === -1 && (
														<div id="alertFail" className="alert alert-danger" role="alert">Si è verificato un errore imprevisto. Riprovare più tardi</div>
													)}
													</>
													<>
													{displayAlert === 1 && (
														<div id="alertSuccess" className="alert alert-success" role="alert">
															Inserimento dati completato con successo!<br />
														</div>
													)}
													</>
												</>
										)}
										{!loading && (
											<div className="row">
												<div className="col">
													<button type="submit" className="btn btn-primary buttonRed">Salva</button>
												</div>
												{apiResponse?.preview && (
													<>
														<div className="col">
															<button type="button" className="btn btn-primary" onClick={()=> window.open(`/web/${apiResponse.preview}`, "_blank")}>Anteprima dei dati salvati</button>
														</div>
														<div className="col">
															<button type="button" className="btn btn-dark" onClick={askDelete}>Cancella la pagina</button>
														</div>
													</>
												)}
											</div>
										)}
									</div>
								</div>
							</form>
						</div>
					</div>
				</>
			):(
				<div className="text-center">
					<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
				</div>
			)}
		</BasePage>
	);
}

export default WebAdmin;