import React from 'react';
import { Link } from 'react-router-dom';

import BasePage from '../Components/BasePage';
import Card from '../Components/Card';

import { DEV } from "../Config/constants";

/***** SUPPLIER *****/
function HomeSupplier() {
	return (
		<BasePage title="La tua Home Concedente">
			<div className="row">
				<div className="col">
					<Link to="/allRentals">
					<Card
						titolo="NOLEGGI"
						testo="Elenco noleggi effettuati"
						icona="fa fa-check fa-4x"
					/>
					</Link>
				</div>
			</div>

			{/* DEV ONLY */}
			{DEV && (
				<div className="row">
					<div className="col">
						<Link to="/saledSubscriptions">
							<Card 
								titolo="ABBONAMENTI"
								testo="Elenco Abbonamenti venduti"
								icona="fa fa-id-card-o fa-4x"
							/>
						</Link>
					</div>
				</div>
			)}
			
			<div className="row">
				<div className="col">
					<Link to="/mopedList">
					<Card
						titolo="I TUOI eMoped"
						testo="Elenco e mappa"
						gIcona="electric_moped"
					/>
					</Link>
				</div>
			</div>
			
			<div className="row">
				<div className="col">
					<Link to="/bikesList">
					<Card
						titolo="LE TUE eBike"
						testo="Elenco e mappa"
						gIcona="electric_bike"
					/>
					</Link>
				</div>
			</div>
			
			<div className="row">
				<div className="col">
					<Link to="/scooterList">
					<Card
						titolo="I TUOI eScooter"
						testo="Elenco e mappa"
						gIcona="electric_scooter"
					/>
					</Link>
				</div>
			</div>
			
			<div className="row">
				<div className="col">
					<Link to="/batteries">
					<Card 
						titolo="BATTERIE"
						testo="Gestione batterie"
						icona="fa fa-battery-three-quarters fa-4x"
					/>
					</Link>
				</div>
			</div>
			
			<div className="row">
				<div className="col">
					<Link to="/webAdmin">
					<Card 
						titolo="PAGINA AZIENDALE"
						testo="Gestione pagina aziendale"
						gIcona="web"
					/>
					</Link>
				</div>
			</div>
			
		</BasePage>
	);
}

export default HomeSupplier;