import React from 'react';
import {MAIN_SITE} from "../Config/constants";

function DisplayFiles(props) {
    let titles = [];
    let links = [];
	
    if (props.files) {
        for(var pdfFile in props.files) {
			titles.push(pdfFile);
			links.push(props.files[pdfFile]);
        }
    }
    return (
        <div className="row my-3">
		{
			titles.map(function(item, i){
				var styles = {'textAlign': 'center', 'font-size': '2em'};
				if (!props.noBorder) {
					styles.border = '1px solid red';
				}
				return (
					<div key={item} className="col p-2 mx-1" style={styles}>
						<a className="dark" href={MAIN_SITE + "/" + links[i]} target="_blank" rel="noreferrer">
							<i key={item} className="fa fa-file-pdf-o fa-2x textRed" aria-hidden="true"></i><br />
							{item}
						</a>
					</div>
				)
			})
		}
		</div>
    )
}

export default DisplayFiles;
