import React from 'react';

import './css/Footer.css';
import { VERSION } from "../Config/constants";

function Footer(props) {
	return (
		<footer>
			<div className="container-fluid roundRedBottom pt-3 pb-3">
				<div className="row">
					<div className="col-md-3">
					</div>
					<div className="col-md-6 text-center">
						<img className="footerLogo" src="/images/logo.png" alt="{props.appName}" />
						<p className="textWhite" style={{margin: '0px', 'fontSize': '0.8em'}}><em>version {VERSION}</em></p>
					</div>
					<div className="col-md-3">
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;