import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import BasePage from '../Components/BasePage';
import Api from "../Services/Api";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import bikePreloader from '../images/bike_preloader.gif'
import loadingGif from '../images/loading.gif'
import moment from 'moment';

/***** CUSTOMER *****/
function Account() {
	const [apiResponse, setApiResponse] = useState(null)
	const [loading, setLoading] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(0);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	
	let history = useHistory();

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	}
	const handleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	}
	
	const formik = useFormik({
		initialValues: {
			name: apiResponse?.name ?? "",
			surname: apiResponse?.surname ?? "",
			birth_date: apiResponse?.birth_date ? moment(apiResponse?.birth_date).format('YYYY-MM-DD') : "",
			email: apiResponse?.email ?? "",
			phone_number: apiResponse?.phone_number ?? "",
			password: '',
			password_confirmation: '',
		},
		validationSchema: Yup.object({
			name:
				Yup.string()
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			surname:
				Yup.string()
				.max(50, 'Lunghezza massima 50 caratteri')
				.required('Campo obbligatorio'),
			birth_date:
				Yup.date()
				.required('Campo obbligatorio'),
			phone_number:
				Yup.string()
				.matches(/^\d{9,10}$/, 'Formato non valido')
				.required('Campo obbligatorio'),
			password:
				Yup.string()
				.min(8, 'Lunghezza minima 8 caratteri'),
			password_confirmation:
				Yup.string()
				.oneOf([Yup.ref('password')], 'Le password non corrispondono'),
		}),
		onSubmit: values => {
			setLoading(true);

			try {
				Api.post({endpoint: 'customer/me', body: values, contentType : 'application/json'})
					.then(response => {
						setDisplayAlert(1);
						let timer1 = setTimeout(() => {setLoading(false);clearTimeout(timer1)}, 3000);
					})
					.catch(error => {
						if (error.response.status === 422) {
							var errors = JSON.parse(error.request.response);
							if (errors.hasOwnProperty('errors')) {
								var alertMessage = "Si sono verificati errori nei dati:";
								for (var errorField in errors.errors) {
									alertMessage += "\n" + errors.errors[errorField].join(',');
								}
								alert(alertMessage);
								setDisplayAlert(0);
								setLoading(false);
							}
							else {
								console.log(error);
							}
							return;
						}
						if (error.response.status === 401) {
							alert('Impossibile procedere alla registrazione di utenti minori di ' + error.response.data.error + " anni");
							setDisplayAlert(0);
							setLoading(false);
							return;
						}
						setDisplayAlert(-1);
					});
			} catch (error) {
				console.log(error);
				setDisplayAlert(-1);
			}
		},
		enableReinitialize: true,
	});
	
	const getApiData = () => {		
		Api.get({endpoint: `customer/me`, contentType : 'application/json'})
		.then(response => {
			var me = response.data.me;
			setApiResponse(me);
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				alert("Utente disabilitato");
				history.replace('/');
				return;
			}
			history.replace('/homeCustomer');
		});
	}
	
	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps
	
	return (
		<BasePage title="Account">
			{apiResponse ? (
				<>
					<div className="container pt-5 pb-5">
						<div style={{'textAlign':'left'}}>
							<form onSubmit={formik.handleSubmit}>
								<div className="row">
									<div className="col-md-6 form-group">
										<fieldset>
											<label htmlFor="name" className="form-label required">Nome</label>
											<input
												disabled={loading}
												id="name"
												name="name"
												type="text"
												className="form-control"
												placeholder="Nome"
												aria-label="Nome"
												{...formik.getFieldProps('name')}
											/>
											{formik.touched.name && formik.errors.name ? (
												<div className="validationMessage">{formik.errors.name}</div>
											) : null}
										</fieldset>
									</div>
									<div className="col-md-6 form-group">
										<fieldset>
											<label htmlFor="surname" className="form-label required">Cognome</label>
											<input
												disabled={loading}
												id="surname"
												name="surname"
												type="text"
												className="form-control"
												placeholder="Cognome"
												aria-label="Cognome"
												{...formik.getFieldProps('surname')}
											/>
											{formik.touched.surname && formik.errors.surname ? (
												<div className="validationMessage">{formik.errors.surname}</div>
											) : null}
										</fieldset>
									</div>
								</div>
								<div className="row pt-3">
									<div className="col-md-6 form-group">
										<fieldset>
											<label htmlFor="birth_date" className="form-label required">Data di nascita</label>
											<input
												disabled={loading}
												id="birth_date"
												name="birth_date"
												type="date"
												className="form-control"
												aria-label="Data di nascita"
												{...formik.getFieldProps('birth_date')}
											/>
											{formik.touched.birth_date && formik.errors.birth_date ? (
												<div className="validationMessage">{formik.errors.birth_date}</div>
											) : null}
										</fieldset>
									</div>
									<div className="col-md-6 form-group">
										<fieldset>
											<label htmlFor="phone_number" className="form-label required">Numero telefonico</label>
											<input
												disabled={loading}
												id="phone_number"
												name="phone_number"
												type="text"
												className="form-control"
												placeholder="Numero telefonico"
												aria-label="Numero telefonico"
												{...formik.getFieldProps('phone_number')}
											/>
											{formik.touched.phone_number && formik.errors.phone_number ? (
												<div className="validationMessage">{formik.errors.phone_number}</div>
											) : null}
										</fieldset>
									</div>
								</div>
								<div className="row pt-3">
									<div className="col-md-12 form-group">
										<fieldset>
											<label htmlFor="email" className="form-label">Email</label>
											<input
												disabled="true"
												id="email"
												name="email"
												type="email"
												className="form-control"
												placeholder="Email"
												aria-label="Email"
												{...formik.getFieldProps('email')}
											/>
											{formik.touched.email && formik.errors.email ? (
												<div className="validationMessage">{formik.errors.email}</div>
											) : null}
										</fieldset>
									</div>
								</div>
								<div className="row pt-3">
									<div className="col-md-6 form-group">
										<fieldset>
											<label htmlFor="password" className="form-label required">Password (min. 8 caratteri)</label>
											<input
												disabled={loading}
												id="password"
												name="password"
												type={showPassword?"text":"password"}
												className="form-control"
												placeholder="Nuova Password"
												aria-label="Nuova Password"
												autoComplete="off"
												{...formik.getFieldProps('password')}
											/>
											{showPassword ? (
												<i className="fa fa-eye-slash faInsideInput" aria-hidden="true" onClick={handleShowPassword}></i>
											):(
												<i className="fa fa-eye faInsideInput" aria-hidden="true" onClick={handleShowPassword}></i>
											)}
											{formik.touched.password && formik.errors.password ? (
												<div className="validationMessage">{formik.errors.password}</div>
											) : null}
										</fieldset>
									</div>
									<div className="col-md-6 form-group">
										<fieldset>
											<label htmlFor="password_confirmation" className="form-label required">Conferma Password</label>
											<input
												disabled={loading}
												id="password_confirmation"
												name="password_confirmation"
												type={showConfirmPassword?"text":"password"}
												className="form-control"
												placeholder="Conferma Password"
												aria-label="Conferma Password"
												autoComplete="off"
												{...formik.getFieldProps('password_confirmation')}
											/>
											{showConfirmPassword ? (
												<i className="fa fa-eye-slash faInsideInput" aria-hidden="true" onClick={handleShowConfirmPassword}></i>
											):(
												<i className="fa fa-eye faInsideInput" aria-hidden="true" onClick={handleShowConfirmPassword}></i>
											)}
											{formik.touched.password_confirmation && formik.errors.password_confirmation ? (
												<div className="validationMessage">{formik.errors.password_confirmation}</div>
											) : null}
										</fieldset>
									</div>
								</div>
								<div className="row pt-3">
									<div className="col">
										{loading && (
												<>
													<>
													{displayAlert === 0 && (
														<img className="pl-3" src={loadingGif} alt="Loading..."/>
													)}
													</>
													<>
													{displayAlert === -1 && (
														<div id="alertFail" className="alert alert-danger" role="alert">Si è verificato un errore imprevisto. Riprovare più tardi</div>
													)}
													</>
													<>
													{displayAlert === 1 && (
														<div id="alertSuccess" className="alert alert-success" role="alert">
															Dati modificati con successo!
														</div>
													)}
													</>
												</>
										)}
										{!loading && (
											<button type="submit" className="btn btn-primary buttonRed">Modifica dati</button>
										)}
									</div>
								</div>
							</form>
						</div>
					</div>
				</>
			):(
				<div className="text-center">
					<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
				</div>
			)}
		</BasePage>
	);
}

export default Account;