import React from 'react';
import BasePage from '../Components/BasePage';
import Card from '../Components/Card';
import { Link } from 'react-router-dom';

/***** PUBLIC *****/
function OurVeicles() {
	return (
		<BasePage title="I nostri veicoli eMobility">
			<div className="container pt-5 pb-5">
			
				<div className="row">
					<div className="col">
						<Link to="/veiclesListEMoped">
							<Card
								titolo="eMoped"
								testo="Ti presentiamo i nostri eMoped"
								gIcona="electric_moped"
							/>
						</Link>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<Link to="/veiclesListEBike">
							<Card
								titolo="eBike"
								testo="Ti presentiamo le nostre eBike"
								gIcona="electric_bike"
							/>
						</Link>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<Link to="/veiclesListEScooter">
							<Card
								titolo="eScooter"
								testo="Ti presentiamo i nostri eScooter"
								gIcona="electric_scooter"
							/>
						</Link>
					</div>
				</div>			
			</div>			
		</BasePage>
	);
}

export default OurVeicles;