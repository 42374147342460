import React, { useState, useEffect } from 'react';

import BasePage from '../Components/BasePage';
import GMap from '../Components/GMap';
import Api from "../Services/Api";

/***** PUBLIC *****/
function ServicePoints() {
	const [locations, setLocations] = useState(null)
	
	useEffect(() => {
		Api.get({endpoint: 'service/publicListSuppliers', contentType : 'application/json'})
			.then(response => {
				response.data.suppliers.map((supplier) => (
					supplier.icon = 'supplier'
				))
				setLocations(response.data.suppliers);
			})
			.catch(error => {
				setLocations(null);
			});
	}, []);
	return (
		<BasePage title="Punti NOLEGGIO e RICARICA">
			<GMap locations={locations} type="publicListSuppliers"/>
		</BasePage>
	);
}

export default ServicePoints;