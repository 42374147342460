import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import BasePage from '../Components/BasePage';
import IconButton from '../Components/IconButton';
import Api from "../Services/Api";
import bikePreloader from '../images/bike_preloader.gif'
import { Link } from 'react-router-dom';
import './css/NoticesDetails.css';

function NoticesDetails() {
	const [apiResponse, setApiResponse] = useState(null)
	const [subTitle, setSubTitle] = useState("")

	let history = useHistory();
	let {category} = useParams();

	const getApiData = () => {
		Api.get({endpoint: `service/publicNoticesDetails/${category}`, contentType : 'application/json'})
		.then(response => {
			var notices = response.data;
			if (notices.category === null) {
				history.replace('/');
			}
			setSubTitle(notices.category);
			setApiResponse(notices);
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				alert("Utente disabilitato");
				history.replace('/');
				return;
			}
			history.goBack();
		});
	}

	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps
	
	return (
		<BasePage title="Eventi & promozioni" subtitle={subTitle}>
			{apiResponse ? (
				<div className="row">
					{apiResponse.notices.map(function(notice, i) {
						return (						
							<div className="col-md-6">
								<div className="noticeDetail">
									<p className="noticeType">
										{notice.type == 0 ? 
											(
												<span>EVENTO</span>
											) : (
												<span>PROMOZIONE</span>
											)
										}
									</p>
									<p className="noticeBrand">{notice.brand}</p>
									<p className="noticeLocation">{notice.city} ({notice.zip_area})</p>
									<p className="noticeSlogan">{notice.slogan}</p>										
									
									TO DO
									
								</div>
							</div>
						)
					})}
				</div> 
			):(
				<div className="text-center">
					<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
				</div>
			)}
		</BasePage>
	);
}

export default NoticesDetails;