import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Moment from 'react-moment';
import BasePage from '../Components/BasePage';
import Api from "../Services/Api";
import bikePreloader from '../images/bike_preloader.gif'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

/***** SUPPLIER *****/
function SaledSubscriptions() {
	const [apiResponse, setApiResponse] = useState(null)

	let history = useHistory();

	let today = new Date();
	let pastDay = new Date(today.getFullYear(), today.getMonth(), 2);

	today = today.toISOString().substring(0, 10);
	pastDay = pastDay.toISOString().substring(0, 10);

	const formik = useFormik({
		initialValues: {
			from_date: pastDay,
			to_date: today,
		},
		validationSchema: Yup.object({
			from_date:
				Yup.date()
				.required('Campo obbligatorio'),
			to_date:
				Yup.date()
				.required('Campo obbligatorio'),
		}),
		onSubmit: values => {
			refreshPage()
		},
	});

	const getApiData = () => {
		Api.get({endpoint: `supplier/saledSubscriptions/${formik.values.from_date}/${formik.values.to_date}`, contentType : 'application/json'})
		.then(response => {
			var subscriptions = response.data;
			setApiResponse(subscriptions);
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				alert("Utente disabilitato");
				history.replace('/');
				return;
			}
			history.replace('/homeSupplier');
		});
	}

	const refreshPage = () => {
		getApiData();
	}

	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<BasePage title="Elenco Abbonamenti venduti">
			{apiResponse ? (
				<div className="container pt-2 pb-5">
					<div className="row mb-3">
						<div className="col">
							<div className="row mt-2">
								<div className="col">
									<label htmlFor="from_date" className="form-label required">Data acquisto da:</label>
								</div>
								<div className="col">
									<input
										id="from_date"
										name="from_date"
										type="date"
										className="form-control"
										style={{width: 'auto', marginLeft: '10px'}}
										aria-label="Data inizio"
										{...formik.getFieldProps('from_date')}
									/>
								</div>
							</div>
							<div className="row mt-2">
								<div className="col">
									<label htmlFor="to_date" className="form-label required">Data acquisto a:</label>
								</div>
								<div className="col">
									<input
										id="to_date"
										name="to_date"
										type="date"
										className="form-control"
										style={{width: 'auto', marginLeft: '10px'}}
										aria-label="Data fine"
										{...formik.getFieldProps('to_date')}
									/>
								</div>
							</div>
						</div>
						<div className="col">
							<button className="btn btn-primary buttonRed mt-1" onClick={refreshPage}>Aggiorna la pagina <i className="fa fa-refresh" aria-hidden="true"></i></button>
							<Link to="/newSubscription">
								<button className="btn btn-primary btn_secondary_function mx-3 mt-2" onClick={refreshPage}>Nuovo abbonamento <i className="fa fa-credit-card" aria-hidden="true"></i></button>
							</Link>					
						</div>
					</div>
					<div className="table-responsive">
						<table className="table shadow">
							<thead className="bckRed text-white">
								<tr>
									<th scope="col">Abbonamento</th>
									<th scope="col">Acquisto</th>
									<th scope="col">Scadenza</th>
									<th scope="col">Cliente</th>
									<th scope="col">Costo al pubblico</th>
									<th scope="col">Costo interno</th>
								</tr>
							</thead>
							<tbody>
								{
									apiResponse.subscriptions.map(function(subscription, i) {
										return (
											<tr key={subscription.idSubscription}>
												<td>{subscription.subscription}</td>
												<td><Moment format="DD/MM/YYYY HH:mm:ss">{subscription.saled_at}</Moment></td>
												<td><Moment format="DD/MM/YYYY HH:mm:ss">{subscription.expire_at}</Moment></td>
												<td>{subscription.customer}</td>
												<td>{subscription.public_price}</td>
												<td>{subscription.private_price}</td>
											</tr>
										)
									})
								}
								<tr key="totals">
									<td colSpan="4">
										<h2><strong>Importi totali per il periodo selezionato</strong></h2>
									</td>
									<td>
										<h3>
											<span style={{'fontWeight': 'bold'}}>{apiResponse.totals.tot_public_price}</span>
										</h3>
									</td>
									<td>
										<h3>
											{apiResponse.totals.tot_private_price}
										</h3>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

			):(
				<div className="text-center">
					<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
				</div>
			)}
		</BasePage>
	);
}

export default SaledSubscriptions;