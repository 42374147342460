import React from 'react';
import './css/IconButton.css';

function IconButton(props) {
	return (
		<div className="card mb-3 shadow border-0" style={{width: "250px"}}>
			<div className="row g-0">
				<div className="col">
					<div className="card-body">
						<p className="card-title" style={{'fontSize': '1.3em'}}>
							{props.titolo}
							{props.badge && (
								<span className="iconBadge">
									<i aria-hidden="true">{props.badge}</i>
								</span>
							)}
						</p>
					</div>
				</div>
			</div>
			<div className="row g-0 pt-2">
				<div className="col" style={{color: "#CD0017"}}>
					{props.icona && (
						<i className={props.icona} aria-hidden="true"></i>
					)}
					{props.gIcona && (
						<span className="material-icons" style={{'fontSize': '4em'}}>{props.gIcona}</span>
					)}
				</div>
			</div>
		</div>
	);
}

export default IconButton;

