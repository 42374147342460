import {Fragment} from 'react'
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../Auth/ProvideAuth'

const RouteManager = ({ component: Component, path, role, veicle, ...rest }) => {
	let auth = useAuth();

	return (
        <Fragment>
    		<Route
    			path={path}
    			render={(props) => {
                    if(role.localeCompare("") !== 0 && (auth.solUser == null || role.localeCompare(auth.solUser.roles[0].name) !== 0))
                        return <Redirect to={{ pathname: '/login' }} />
                    else
    					return <Component veicle = {veicle} {...props} />
                    }
    			}
    			{...rest}
    		/>
        </Fragment>
	);
}

export default RouteManager;