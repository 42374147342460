import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Moment from 'react-moment';
import BasePage from '../Components/BasePage';
import Api from "../Services/Api";
import bikePreloader from '../images/bike_preloader.gif'
import './css/Subscriptions.css';
import QRCode from 'react-qr-code';
import {APP_NAME} from "../Config/constants";

/***** CUSTOMER *****/
function Subscriptions() {
	const [apiResponse, setApiResponse] = useState(null)

	const [qrValue, setQrValue] = useState();
	let qrback = '#FFFFFF';
	let qrfore = '#000000';
	let qrsize = 160;

	let history = useHistory();

	const getApiData = () => {		
		Api.get({endpoint: `customer/subscriptions`, contentType : 'application/json'})
		.then(response => {
			var subscriptions = response.data;
			setApiResponse(subscriptions);
			setQrValue(subscriptions.uuid);
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				alert("Utente disabilitato");
				history.replace('/');
				return;
			}
			history.replace('/homeCustomer');
		});
	}

	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps
	
	return (
		<BasePage title="Elenco dei tuoi ABBONAMENTI">
			{apiResponse ? (
				<>
					<div className="row qrCodeBox">
						<div className="col-md-8" style={{'padding-top':'10px', 'padding-bottom':'10px'}}>
							Mostra questo QRcode al venditore per acquistare un abbonamento<br /><br />
							<button onClick={() => window.location.reload()} className="btn btn-primary btn_secondary_function" >AGGIORNA PAGINA</button>
						</div>
						<div className="col-md-4">
							{qrValue ? (
								<QRCode
									title={APP_NAME}
									value={qrValue}
									bgColor={qrback}
									fgColor={qrfore}
									size={qrsize === '' ? 0 : qrsize}
								  />
								) : (
									<span>Caricamento in corso...</span>
								)
							}
						</div>
					</div>
					<div className="container pt-2 pb-5">
						<div className="table-responsive">
							<table className="table shadow">
								<thead className="bckRed text-white">
									<tr>
										<th scope="col">Abbonamento</th>
										<th scope="col">Acquisto</th>
										<th scope="col">Scadenza</th>
										<th scope="col">Venditore</th>
										<th scope="col">Prezzo</th>
									</tr>
								</thead>
								<tbody>
									{
										apiResponse.subscriptions.map(function(subscription, i) {
											return (
												<tr key={subscription.idSubscription} className={subscription.expired ? "expiredSubscription" : ""}>
													<td>{subscription.subscription}</td>
													<td><Moment format="DD/MM/YYYY HH:mm:ss">{subscription.saled_at}</Moment></td>
													<td><Moment format="DD/MM/YYYY HH:mm:ss">{subscription.expire_at}</Moment></td>
													<td>{subscription.supplier}</td>
													<td>{subscription.price}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>								
						</div>
					</div>
				</>
			):(
				<div className="text-center">
					<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
				</div>
			)}
		</BasePage>
	);		
}

export default Subscriptions;