import React, { useRef, useState, useEffect } from 'react';

import BasePage from '../Components/BasePage';
import GMap from '../Components/GMap';
import Api from "../Services/Api";

/***** SUPPLIER *****/
function BikesList(props) {
	var title = "Elenco e mappa";
	
	const childRef = useRef();

	if (props.veicle.localeCompare("eBike") === 0) {
		title += " delle tue eBike";
	}
	if (props.veicle.localeCompare("eScooter") === 0) {
		title += " dei tuoi eScooter";
	}
	
	var textFromStatus = {
		'WAIT_START': 'In attesa ATTIVAZIONE', 
		'GO': 'In NOLEGGIO', 
		'WAIT_STOP': 'In attesa DISATTIVAZIONE', 
		'NORENT': 'LIBERO', 
	}
	
	const [locations, setLocations] = useState(null)
	
	const refreshPage = () => {	
		getApiData();
	}
	
	const getApiData = () => {
		Api.get({endpoint: 'supplier/locateAllDevices/' + props.veicle, contentType : 'application/json'})
		.then(response => {
			response.data.positions.map((bike) => {
				bike.iconOn = 'bikeOn'
				bike.iconOff = 'bikeOff'
				return null;
			})
			setLocations(response.data.positions);
		})
		.catch(error => {
			setLocations(null);
		});
	}
	
	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps
	
	const requestPersonalRent = (bike_imei) => {	
		if (!window.confirm("Attivare IMMEDIATAMENTE il noleggio di questa eBike per uso personale?\nIl noleggio sarà soggetto alle normali tariffe interne in vigore"))
			return;
		Api.get({endpoint: 'supplier/personalRent/'+bike_imei, contentType : 'application/json'})
			.then(response => {
				if (response.status && response.status === 228) {
					alert("L'eBike non è raggiungibile.\nSpostarla e riprovare");
					return;
				}
				if (response.status && response.status === 224) {
					alert("Impossibile localizzare correttamente l'eBike.\nSpostarla e riprovare");
					return;
				}
				if (response.status && response.status === 223) {
					alert('L\'eBike risulta già accesa!');
					return;
				}				
				if (response.status && response.status === 200) {
					getApiData();
					alert('Noleggio attivato!');
					return;
				}
				alert('Impossibile eseguire l\'operazione\nRivolgiti all\'assistenza per supporto');				
			})
			.catch(error => {
				getApiData();
				alert("Problemi nell'attivazione del noleggio");
			});
	}
	
	const publicCenterMap = (latitude, longitude) => {	
		childRef.current.centerMap(latitude, longitude);		
	}
	
	return (
		<BasePage title={title}>
			<GMap ref={childRef} locations={locations} type="listSupplierBikes"/>			
			<div className="mb-2">
				<button className="btn btn-primary buttonRed" onClick={refreshPage}>Aggiorna la pagina <i className="fa fa-refresh" aria-hidden="true"></i></button>
			</div>
							
			<div className="table-responsive">
				<table className="table shadow">
					<thead className="bckRed text-white">
						<tr>
							<th scope="col">N.veicolo</th>
							<th scope="col">Centra mappa</th>
							<th scope="col">Modello</th>
							<th scope="col">Telaio</th>
							<th scope="col">Ultimo posizionamento</th>
							<th scope="col">Ultimo contatto</th>
							<th scope="col">Posizione</th>
							<th scope="col">Batteria inserita</th>
							<th scope="col">Stato</th>
							<th scope="col">Comandi</th>
						</tr>
					</thead>
					<tbody>						
						{locations && (
							locations.map(function(bike, i){
								return (
									<tr key={bike.bikeNumber}>
										<td>{bike.bikeNumber}</td>
										<td className="text-center">
											<i 
												className="fa fa-crosshairs cliccable" 
												aria-hidden="true"
												onClick={() => { 												
													publicCenterMap(bike.latitude, bike.longitude);													
												}}
													
											></i>
										</td>
										<td>{bike.bikeModel}</td>
										<td>{bike.frameNumber}</td>
										<td>{bike.gpsTime}</td>
										<td>{bike.hbTime}</td>
										<td><a target="_blank" href={bike.mapLink} rel="noreferrer">&nbsp;&nbsp;<i className="fa fa-map-marker cliccable" aria-hidden="true"></i></a></td>
										<td>
											{bike.batteryConnected.localeCompare("true") === 0 ? (
												<i className="fa fa-check-square-o" aria-hidden="true"></i>
											) : (
												<i className="fa fa-square-o" aria-hidden="true"></i>
											)}
										</td>
										<td>										
											<i className={`fa fa-circle fa-1x px-2 ${bike.bikeStatus.toLowerCase()}`} aria-hidden="true"></i>
											{textFromStatus[bike.bikeStatus]}
										</td>
										<td>
											{bike.bikeStatus.localeCompare("NORENT") === 0 && (
												<button onClick={() => requestPersonalRent(bike.imei)} className="btn btn-primary buttonRed">Attiva noleggio personale</button>
											)}
										</td>
									</tr>
								)
							})
						)}
					</tbody>
				</table>
			</div>
		</BasePage>
	);
}

export default BikesList;