import React from 'react';
import BasePage from '../Components/BasePage';
import ComingSoon from '../Components/ComingSoon';

/***** SUPPLIER *****/
function Batteries() {
	return (
		<BasePage title="Gestione batterie">
			<ComingSoon/>
		</BasePage>
	);
}

export default Batteries;