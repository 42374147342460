import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import BasePage from '../Components/BasePage';
import Api from "../Services/Api";
import ComingSoon from '../Components/ComingSoon';

/***** PUBLIC *****/
function VeiclesList(props) {
	var title = "";
	const [veicles, setVeicles] = useState(null)
	const [typeComingSoon, setTypeComingSoon] = useState(false)

	let history = useHistory();

	if (props.veicle.localeCompare("eMoped") === 0) {
		title = "I nostri eMoped";
	}
	if (props.veicle.localeCompare("eBike") === 0) {
		title = "Le nostre eBike";
	}
	if (props.veicle.localeCompare("eScooter") === 0) {
		title = "I nostri eScooter";
	}

	const getApiData = () => {
		Api.get({endpoint: 'service/publicListVeicles/' + props.veicle, contentType : 'application/json'})
		.then(response => {
			var rawVeicles = response.data.veicles;
			setVeicles(rawVeicles);
			if (!rawVeicles || rawVeicles.length === 0) {
				setTypeComingSoon(true);
			}
		})
		.catch(error => {
			if (error.response && error.response.status === 401) {
				alert("Utente disabilitato");
				history.replace('/');
				return;
			}
			history.replace('/');
		});
	}

	useEffect(() => {
		getApiData();
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<BasePage title={title}>
			{veicles && (
				<div key="base">
					{typeComingSoon ? (
						<ComingSoon/>
					) : (
						veicles.map(function(veicle, i) {
							return (
								<div key={i} className="row my-4">
									<div key={`img_${i}`} className="col-md-8">
										<img className="responsive" style={{'width': '100%'}}src={veicle.image} alt={veicle.description} />
									</div>
									<div key={`text_${i}`} className="col-md-4">
										<p key={`description_${i}`}><strong>Modello:</strong><br />{veicle.description}</p>
										<p key={`activation_price_${i}`}><strong>Attivazione:</strong><br />
											{veicle.activation_price}€
											{/*
												{veicle.subscriptions.map((subscription, n) => (
													<span key={`activation_price_${n}_${i}`} style={{'marginLeft': '10px'}}>
														{subscription.activation_price}€
														{"*".repeat(n+1)}
													</span>
												))}
											*/}
										</p>
										<p key={`rent_unit_price_${i}`}><strong>Costo al minuto:</strong><br />
											{(veicle.rent_unit_price / 100).toFixed(2)}€
											{/*
												{veicle.subscriptions.map((subscription, n) => (
													<span key={`rent_unit_price_${n}_${i}`} style={{'marginLeft': '10px'}}>
														{(subscription.rent_unit_price / 100).toFixed(2)}€
														{"*".repeat(n+1)}
													</span>
												))}
											*/}
										</p>
										<p key={`max_euros_day_${i}`}><strong>Costo max giornaliero:</strong><br />
											{veicle.max_euros_day}€
											{/*
												{veicle.subscriptions.map((subscription, n) => (
													<span key={`max_euros_day_${n}_${i}`} style={{'marginLeft': '10px'}}>
														{subscription.max_euros_day.toFixed(2)}€
														{"*".repeat(n+1)}
													</span>
												))}
											*/}
										</p>
										{veicle.subscriptions.map((subscription, i) => (
												<p key={`main_subscription_${i}`}>
													<strong>Abbonamento {subscription.name}</strong>{"*".repeat(i+1)}<br />
													{subscription.discounted_price ? (
														<>
															<span style={{'textDecoration': 'line-through', 'marginRight': '5px'}}>{subscription.price}€</span>
															{subscription.discounted_price}€
														</>
													) : (
														<span>{subscription.price}€</span>
													)}
													{subscription.claim ? (
														<span> - {subscription.claim}</span>
													) : (
														<></>
													) }
												</p>
										))}
									</div>
								</div>
							)
						})
					)}
					<p>
						<strong>
							I prezzi esposti possono essere soggetti a variazioni in base alle zone di competenza<br />
							La stipula dell’Abbonamento MENSILE, MENSILE FULL e HOME, non garantisce la disponibilità immediata dei veicoli eMobility.
						</strong>
					</p>
				</div>
			)}
		</BasePage>
	);
}

export default VeiclesList;