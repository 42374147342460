import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BasePage from '../Components/BasePage';
import CurrentRentDetails from '../Components/CurrentRentDetails';
import Api from "../Services/Api";
import bikePreloader from '../images/bike_preloader.gif' 

/***** CUSTOMER *****/
function CurrentRent() {	
	const [authorized, setAuthorized] = useState(false)
	const [apiResponse, setApiResponse] = useState(null)
	const [commandsDisabled, setCommandsDisabled] = useState(false)
	const [veicle, setVeicle] = useState("eMobility")
	const [locations, setLocations] = useState(null)

	let history = useHistory();
	let intervalApi;
	let intervalTime;
	let apiEnabled = true;
	const refreshTime = 5000;
	
	if (!localStorage.hasOwnProperty("rent_token")) {
		history.replace('/homeCustomer');
	}	
	var rent_token = localStorage.getItem('rent_token');

	const stopRent = (removeToken = true, redirectUrl = true) => {
		clearInterval(intervalApi);
		clearInterval(intervalTime);
		apiEnabled = false;
		if (removeToken)
			localStorage.removeItem('rent_token');		
		if (redirectUrl)
			history.replace('/homeCustomer');
	}

	const setApiData = (code) => {	
		setCommandsDisabled(true);
		Api.get({endpoint: 'customer/updateRent/'+rent_token+'/'+code, contentType : 'application/json'})
			.then(response => {		
				switch (code) {
					case 'REVOKE':					
					break;
				case 'END':
					break;					
				case 'OFF':
					alert(veicle + ' spenta!');
					break;
				case 'ON':
					if (response.status && response.status === 228) {
						alert("L'" + veicle + " non è raggiungibile.\nSpostarla e riprovare");					
						break;
					}
					if (response.status && response.status === 224) {
						alert("Impossibile localizzare correttamente l'" + veicle + ".\nScollegare la batteria, attendere 5secondi, ricollegarla e riprovare");		
						break;
					}	
					if (response.status && response.status === 223) {
						alert("L'" + veicle + " risulta già accesa!");
						break;
					}					
					if (response.status && response.status === 200) {
						alert(veicle + " accesa!");
						break;
					}
					alert('Impossibile eseguire l\'operazione\nRivolgiti al fornitore per supporto');
					break;
				default:
					//NOP
				}		
				getApiData();	//Force refresh
				setCommandsDisabled(false);
			})
			.catch(error => {
				setCommandsDisabled(false);
				if (error.response && error.response.status === 401) {
					alert("Utente disabilitato");
					stopRent(true, false);
					history.replace('/');
					return;
				}				
				if (error.response && error.response.status === 403) {
					alert("Token non valido");
					stopRent(true);
					return;
				}				
				if (error.response && error.response.status === 224) {
					alert("Impossibile localizzare correttamente l'" + veicle + ".\nSpostarla e riprovare");
					return;
				}				
				alert('Impossibile eseguire l\'operazione\nRivolgiti al fornitore per supporto');
			});
	}

	const getApiData = () => {	
		apiEnabled && 
			Api.get({endpoint: 'customer/currentRent/'+rent_token, contentType : 'application/json'})
			.then(response => {		
				var currentRental = response.data;				
				if (!currentRental.rental) {
					stopRent(true);
					return;					
				}
				setVeicle(currentRental.rental.bike.size.veicle);
				setAuthorized(true);
				setApiResponse(currentRental);
			})
			.catch(error => {
				if (error.response && error.response.status === 401) {
					alert("Utente disabilitato");
					stopRent(true, false);
					history.replace('/');
					return;
				}				
				if (error.response && error.response.status === 403) {
					alert("Token non valido");
					stopRent(true);
					return;
				}
				stopRent(false);
			});
	}
	
	const getPublicListSuppliers = () => {	
		Api.get({endpoint: 'service/publicListSuppliers', contentType : 'application/json'})
			.then(response => {
				response.data.suppliers.map((supplier) => (
					supplier.icon = 'supplier'
				))
				setLocations(response.data.suppliers);
			})
			.catch(error => {
				setLocations(null);
			});
	}

	useEffect(() => {
		getApiData();
		getPublicListSuppliers();
		
		const unblock = history.block((location, action) => {
			stopRent(false, false);
			return true;
		});

		intervalApi = setInterval(()=>{	// eslint-disable-line react-hooks/exhaustive-deps
			getApiData()
		},refreshTime)

		return () => {
			unblock();
		};
	}, []);	// eslint-disable-line react-hooks/exhaustive-deps

	var subtitle = "La pagina si aggiorna ogni " + refreshTime/1000 + " secondi";

	return (
		<BasePage title="Il tuo Noleggio" subtitle={subtitle}>			
			{authorized ? (
				<CurrentRentDetails rental={apiResponse} callback={setApiData} stopCallback={stopRent} commandsDisabled={commandsDisabled} veicle={veicle} locations={locations} />
			):(
				<div className="text-center">
					<img style={{'width':'100px'}} className="pl-3" src={bikePreloader} alt="Loading..."/>
				</div>	
			)}
		</BasePage>
	);
}

export default CurrentRent;